var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { toExpectedRestAmount } from 'src/common';
import { styleMinWidth, styleMaxWidth } from 'src/common/styleUtil';
import { TextMapContext, Web3Context } from 'src/context';
import styled from 'styled-components';
var IMG_BASE_URL = process.env.IMG_BASE_URL;
var FeedingItem = function (_a) {
    var tokenId = _a.tokenId, config = _a.config, onClaimClick = _a.onClaimClick;
    var web3Service = useContext(Web3Context)[0];
    var _b = useState(0), amount = _b[0], setAmount = _b[1];
    var _c = useState(false), isPaused = _c[0], setIsPaused = _c[1];
    var _d = useContext(TextMapContext), textMap = _d[0], locale = _d[1];
    function update() {
        return __awaiter(this, void 0, void 0, function () {
            var _amount;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, web3Service.getClaimableFeed(tokenId)];
                    case 1:
                        _amount = _a.sent();
                        if (!isPaused) {
                            setAmount(Math.floor(Number(_amount) / Math.pow(10, 15)) / 1000); // prevent unmounted state change error
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        var toid = setInterval(update, 2000);
        return function () {
            setIsPaused(true);
            clearInterval(toid);
        };
    }, [tokenId]);
    var startedAt = config.startedAt, recentlyClaimedAt = config.recentlyClaimedAt, hashPower = config.hashPower, limit = config.limit;
    var expectedRestAmount = Math.round(toExpectedRestAmount(limit, startedAt, recentlyClaimedAt, hashPower) / Math.pow(10, 15)) / 1000;
    var viewHashPower = Math.round(Number(hashPower) / Math.pow(10, 9));
    return (React.createElement(FeedingItemWrapper, null,
        React.createElement(FeedingItemImg, { src: "".concat(IMG_BASE_URL).concat(tokenId, ".png"), alt: "image of ".concat(tokenId) }),
        React.createElement(FeedingItemDashboard, null,
            React.createElement(TokenId, null,
                "#",
                Number(tokenId)),
            React.createElement(InfoRow, null,
                React.createElement("h1", null, textMap['HashPower']),
                React.createElement("h2", null,
                    React.createElement("b", null, viewHashPower),
                    " gwei/s")),
            React.createElement(InfoRow, null,
                React.createElement("h1", null, textMap['GrantsLimit']),
                React.createElement("h2", null,
                    React.createElement("b", null, expectedRestAmount),
                    " FEED")),
            React.createElement(InfoRow, null,
                React.createElement("h1", null, textMap['Claimable']),
                React.createElement(Amount, null,
                    React.createElement("b", null, amount),
                    " FEED")),
            React.createElement(ClaimFeedButton, { onClick: function () { return onClaimClick(tokenId); } }, textMap['Claim']))));
};
var InfoRow = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  text-overflow: ellipsis;\n  margin: 10px 0;\n  ", "\n  ", "\n  h1 {\n    color: #b7b7b7;\n  }\n  h2 {\n    color: #bbb;\n    font-size: 14px;\n    ", "\n    b {\n      font-size: 14px;\n      color: #fff;\n      width: 80px;\n      overflow: hidden;\n      text-overflow: ellipsis;\n      display: inline-block;\n      vertical-align: bottom;\n      ", "\n      ", "\n    }\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  text-overflow: ellipsis;\n  margin: 10px 0;\n  ", "\n  ", "\n  h1 {\n    color: #b7b7b7;\n  }\n  h2 {\n    color: #bbb;\n    font-size: 14px;\n    ", "\n    b {\n      font-size: 14px;\n      color: #fff;\n      width: 80px;\n      overflow: hidden;\n      text-overflow: ellipsis;\n      display: inline-block;\n      vertical-align: bottom;\n      ", "\n      ", "\n    }\n  }\n"])), styleMinWidth(767, "\n  "), styleMaxWidth(767, "\n    flex-direction: column;\n  "), styleMaxWidth(767, "\n      font-size: 0;\n    "), styleMinWidth(767, "\n        text-align: right;\n      "), styleMaxWidth(767, "\n        text-align: center;\n      "));
var ClaimFeedButton = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 10px 0;\n  border-radius: 5px;\n  background: #8585ae;\n  font-weight: bold;\n  font-family: 'BMJUA';\n  font-size: 22px;\n  border: 0;\n  cursor: pointer;\n  &:hover {\n    background: white;\n  }\n  transition: background 100ms ease;\n\n  ", "\n  ", "\n"], ["\n  padding: 10px 0;\n  border-radius: 5px;\n  background: #8585ae;\n  font-weight: bold;\n  font-family: 'BMJUA';\n  font-size: 22px;\n  border: 0;\n  cursor: pointer;\n  &:hover {\n    background: white;\n  }\n  transition: background 100ms ease;\n\n  ", "\n  ", "\n"])), styleMinWidth(767, "\n    position: absolute;\n    bottom: 20px;\n    left: 20px;\n    right: 20px;\n  "), styleMaxWidth(767, "\n    width: 100%;\n  "));
var TokenId = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  text-align: right;\n  font-weight: bold;\n  font-size: 20px;\n  color: #fdf837;\n  margin-bottom: 20px;\n"], ["\n  text-align: right;\n  font-weight: bold;\n  font-size: 20px;\n  color: #fdf837;\n  margin-bottom: 20px;\n"])));
var FeedingItemWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: inline-flex;\n  position: relative;\n  ", "\n  ", "\n  overflow: hidden;\n"], ["\n  display: inline-flex;\n  position: relative;\n  ", "\n  ", "\n  overflow: hidden;\n"])), styleMinWidth(767, "\n    width: 40%;\n    padding: 3%;\n  "), styleMaxWidth(767, "\n    width: 100%;\n    padding: 3% 0;\n  "));
var FeedingItemDashboard = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 50%;\n  border-radius: 0 15px 15px 0;\n  background: #363677;\n  padding: 10px;\n  position: relative;\n"], ["\n  width: 50%;\n  border-radius: 0 15px 15px 0;\n  background: #363677;\n  padding: 10px;\n  position: relative;\n"])));
var FeedingItemImg = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 50%;\n  height: auto;\n  border-radius: 15px 0 0 15px;\n  object-fit: cover;\n"], ["\n  width: 50%;\n  height: auto;\n  border-radius: 15px 0 0 15px;\n  object-fit: cover;\n"])));
var Amount = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  text-align: center;\n  margin-top: 10px;\n  b {\n    font-size: 24px;\n    font-weight: bold;\n    color: white;\n  }\n  font-size: 16px;\n  font-weight: normal;\n  color: #aaa;\n"], ["\n  text-align: center;\n  margin-top: 10px;\n  b {\n    font-size: 24px;\n    font-weight: bold;\n    color: white;\n  }\n  font-size: 16px;\n  font-weight: normal;\n  color: #aaa;\n"])));
export default FeedingItem;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
