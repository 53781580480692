import * as React from 'react';
import 'src/pages/Home/RoadmapSection/RoadmapItem/RoadmapItem.scss';
import footImgPath from 'resource/etc/foot.png';
var RoadmapItem = function (props) {
    var goal = props.goal, desc = props.desc;
    return (React.createElement("div", { className: 'roadmap-item' },
        React.createElement("div", { className: 'roadmap-item__wrapper' },
            React.createElement("div", { className: 'roadmap-item__sign' },
                React.createElement("img", { src: footImgPath }),
                React.createElement("div", { className: 'goal-text' },
                    goal,
                    "%",
                    goal < 10 && '\u00A0\u00A0')),
            React.createElement("div", { className: 'roladmap-item__desc' }, desc))));
};
export default RoadmapItem;
