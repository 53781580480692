export function styleMaxWidth(maxWidth, style) {
    return "\n    @media screen and (max-width: ".concat(maxWidth, "px) {\n      ").concat(style, "\n    }\n  ");
}
export function styleMinWidth(minWidth, style) {
    return "\n    @media screen and (min-width: ".concat(minWidth, "px) {\n      ").concat(style, "\n    }\n  ");
}
export var containerStyle = "\n  position: relative;\n  margin: auto;\n  width: 1280px;\n  max-width: 100%;\n  z-index: 100;\n";
export var BackgroundPallet = {
    intro: "linear-gradient(180deg,\n    rgb(0,0,0) 0%,\n    rgb(0,0,30) 35%,\n    rgb(5,5,60) 100%)",
    race: "linear-gradient(180deg,\n    rgb(5,5,60) 0%,\n    rgb(0,0,30) 20%\n  )",
    roadmapTop: "linear-gradient(180deg,\n    rgb(0,0,30) 0%,\n    #15145800 100%\n  )",
    roadmap: "#151458",
    faq: "#151458",
    faqBottom: "linear-gradient(180deg,\n    #15145800 0%,  \n    rgb(25, 27, 73) 100%\n  )",
    aboutUs: "rgb(25, 27, 73)",
};
export var mainText = "\n  ".concat(styleMinWidth(768, "\n    font-size: 21px;\n  "), "\n  ").concat(styleMaxWidth(768, "\n    font-size: 19px;\n  "), "\n  line-height: 1.5;\n  letter-spacing: -0.5px;\n  color: white;\n  text-align: initial;\n  strong {\n    font-family: inherit;\n    color: #fffdc1;\n    font-weight: bold;\n  }\n");
export var titleText = "\n  margin-bottom: 15px;\n  div {\n    display: inline-block;\n    font-family: 'Berlin Sans';\n    color: #FEF836;\n  }\n  ".concat(styleMinWidth(768, "\n    font-size: 58px;\n  "), "\n  ").concat(styleMaxWidth(768, "\n    font-size: 44px;\n  "), "\n");
