var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from 'react';
import styled from 'styled-components';
import { styleMaxWidth, styleMinWidth, containerStyle, BackgroundPallet, } from 'src/common/styleUtil';
import Staff1ImgPath from 'resource/profile/1.png';
import Staff2ImgPath from 'resource/profile/2.png';
import Staff3ImgPath from 'resource/profile/3.png';
import Staff4ImgPath from 'resource/profile/4.png';
import Staff5ImgPath from 'resource/profile/5.png';
import Staff6ImgPath from 'resource/profile/6.png';
import Staff7ImgPath from 'resource/profile/7.png';
import Staff8ImgPath from 'resource/profile/8.png';
import Staff9ImgPath from 'resource/profile/9.png';
import AllStaffsImgPath from 'resource/profile/members.png';
function AboutItem(_a) {
    var imgPath = _a.imgPath, name = _a.name, position = _a.position;
    return (React.createElement(AboutItemWrapper, null,
        React.createElement(AboutImage, { src: imgPath, alt: name }),
        React.createElement(AboutItemName, null, name),
        React.createElement(AboutItemPosition, null, position)));
}
var AboutSection = function () {
    return (React.createElement(AboutWrapper, null,
        React.createElement(AboutContainer, null,
            React.createElement(AboutTitle, null, "About Us"),
            React.createElement(AboutItemContainer, null,
                React.createElement(AboutItem, { imgPath: Staff1ImgPath, name: 'Jin Do', position: 'Project Leader' }),
                React.createElement(AboutItem, { imgPath: Staff2ImgPath, name: 'Mongoose', position: 'Developer' }),
                React.createElement(AboutItem, { imgPath: Staff3ImgPath, name: 'Kingcobra', position: 'All of One' }),
                React.createElement(AboutItem, { imgPath: Staff4ImgPath, name: 'Ho', position: 'Marketing Leader' }),
                React.createElement(AboutItem, { imgPath: Staff5ImgPath, name: 'TNiaz', position: 'Design Leader' }),
                React.createElement(AboutItem, { imgPath: Staff6ImgPath, name: 'Onufo', position: 'Project Artist' }),
                React.createElement(AboutItem, { imgPath: Staff7ImgPath, name: 'Lotus', position: 'Marketing Manager' }),
                React.createElement(AboutItem, { imgPath: Staff8ImgPath, name: 'Picoco', position: 'PR Manager' }),
                React.createElement(AboutItem, { imgPath: Staff9ImgPath, name: 'Rose', position: 'Community Manager' }))),
        React.createElement(MembersImageWrapper, null,
            React.createElement("img", { src: AllStaffsImgPath, alt: "members" }))));
};
var AboutWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  position: relative;\n  z-index: 100;\n"], ["\n  background: ", ";\n  position: relative;\n  z-index: 100;\n"])), BackgroundPallet.aboutUs);
var AboutContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 150px 0 350px 0;\n  ", "\n"], ["\n  padding: 150px 0 350px 0;\n  ", "\n"])), containerStyle);
var AboutTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 46px;\n  padding: 0 20px;\n  color: #FEF836;\n  font-family: 'Berlin Sans';\n  margin-bottom: 20px;\n  text-shadow: 1px 1px 10px #000;\n"], ["\n  font-size: 46px;\n  padding: 0 20px;\n  color: #FEF836;\n  font-family: 'Berlin Sans';\n  margin-bottom: 20px;\n  text-shadow: 1px 1px 10px #000;\n"])));
var AboutItemContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n  padding: 10px;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-evenly;\n"], ["\n  position: relative;\n  padding: 10px;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-evenly;\n"])));
var AboutItemWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: relative;\n  ", "\n  ", "\n  text-align: center;\n"], ["\n  position: relative;\n  ", "\n  ", "\n  text-align: center;\n"])), styleMinWidth(768, "\n    flex-direction: row;\n    width: 26%;\n    margin: 30px 0;\n  "), styleMaxWidth(768, "\n    flex-direction: column;\n    width: 40%;\n    margin: 10px 0;\n "));
var AboutImage = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  border-radius: 50%;\n  width: 50%;\n  background: #ccc;\n"], ["\n  border-radius: 50%;\n  width: 50%;\n  background: #ccc;\n"])));
var AboutItemName = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: white;\n  font-weight: bold;\n  margin: 10px 0 5px 0;\n  ", "\n  ", "\n"], ["\n  color: white;\n  font-weight: bold;\n  margin: 10px 0 5px 0;\n  ", "\n  ", "\n"])), styleMinWidth(768, "\n    font-size: 22px;\n  "), styleMaxWidth(768, "\n    font-size: 20px;\n  "));
var AboutItemPosition = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: #ccc;\n  margin-bottom: 10px;\n  ", "\n  ", "\n"], ["\n  color: #ccc;\n  margin-bottom: 10px;\n  ", "\n  ", "\n"])), styleMinWidth(768, "\n    font-size: 20px;\n  "), styleMaxWidth(768, "\n    font-size: 14px;\n  "));
var MembersImageWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: absolute;\n  left: 0;\n  bottom: 0;\n  ", "\n  ", "\n  padding: 0;\n  font-size: 0;\n  img {\n    width: 100%;\n  }\n"], ["\n  position: absolute;\n  left: 0;\n  bottom: 0;\n  ", "\n  ", "\n  padding: 0;\n  font-size: 0;\n  img {\n    width: 100%;\n  }\n"])), styleMinWidth(768, "\n    margin: 0 150px;\n  "), styleMaxWidth(768, "\n    margin: 0 10px;\n  "));
export default AboutSection;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
