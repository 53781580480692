var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import WalletSelectPopup from 'src/component/WalletSelectPopup';
import { FaWallet, FaPaw } from 'react-icons/fa';
import { MdInfo } from 'react-icons/md';
import { AddressContext, NetworkIdContext, PopupContext, TextMapContext, Web3Context } from 'src/context';
import SlidesImg1Path from 'resource/intro/g1.gif';
import SlidesImg2Path from 'resource/intro/g2.gif';
import SlidesImg3Path from 'resource/intro/g3.gif';
import SlidesImg4Path from 'resource/intro/g4.gif';
import { styleMaxWidth, styleMinWidth } from 'src/common/styleUtil';
import { getMerkleProof } from 'src/common/merkle';
import SimpleButton from 'src/component/SimpleButton';
var NETWORK_ID = process.env.NETWORK_ID;
;
var MintInnerContainer = function (props) {
    var step = props.step, setStep = props.setStep, publicMintClick = props.publicMintClick, preMintClick = props.preMintClick, payByTokenMintClick = props.payByTokenMintClick;
    var setPopup = useContext(PopupContext);
    var web3Service = useContext(Web3Context)[0];
    var _a = useState(null), message = _a[0], setMessage = _a[1];
    var _b = useState(null), button = _b[0], setButton = _b[1];
    var _c = useState(false), isWhiteListed = _c[0], setIsWhiteListed = _c[1];
    var _d = useContext(TextMapContext), textMap = _d[0], locale = _d[1];
    var _e = useContext(AddressContext), address = _e[0], setAddress = _e[1];
    var _f = useContext(NetworkIdContext), networkId = _f[0], setNetworkId = _f[1];
    useEffect(function () {
        var _a;
        if (networkId === parseInt(NETWORK_ID))
            setStep(4);
        else if (web3Service.getWalletType() !== null)
            setStep(3);
        else if (web3Service.isWalletInstalled())
            setStep(2);
        if (step === 1) {
            setMessage(React.createElement("div", null,
                React.createElement(FaPaw, null),
                " ",
                textMap['InstallWallet']));
            setButton(React.createElement(SimpleButton, { onClick: function () { return window.open('https://chrome.google.com/webstore/search/kaikas'); }, type: 'white', text: textMap['WalletInstall'], textColor: 'black', subText: textMap['LinkToInstallKaikas'], subTextColor: '#666' }));
            setIsWhiteListed(false);
        }
        if (step === 2) {
            setMessage(React.createElement("div", null,
                React.createElement(FaPaw, null),
                " ",
                textMap['ConnectWallet']));
            setButton(React.createElement(SimpleButton, { onClick: function () { return setPopup(React.createElement(WalletSelectPopup, { closeFunc: function () {
                        setPopup(null);
                        setStep(1);
                    } })); }, type: 'white', text: textMap['WalletConnect'], textColor: 'black', subText: "".concat(textMap['CommonKaikas'], ", ").concat(textMap['CommonMetamask']), subTextColor: '#666' }));
            setIsWhiteListed(false);
        }
        if (step === 3) {
            setMessage(React.createElement("div", null,
                React.createElement(FaPaw, null),
                " ",
                textMap['ChangeNetworkToMainnet']));
            setButton(React.createElement(SimpleButton, { onClick: function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var succeed;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, web3Service.changeNetwork(parseInt(NETWORK_ID))];
                                case 1:
                                    succeed = _a.sent();
                                    if (succeed) {
                                        setStep(1);
                                        setNetworkId(parseInt(NETWORK_ID));
                                    }
                                    else {
                                        if (web3Service.getWalletType() === 'kaikas') {
                                            alert(textMap['ChangeNetworkKaikas']);
                                        }
                                        if (web3Service.getWalletType() === 'metamask') {
                                            alert(textMap['AddNetworkToMetamaskDirectly']);
                                        }
                                    }
                                    return [2 /*return*/];
                            }
                        });
                    });
                }, type: 'white', text: textMap['NetworkChange'], textColor: 'black', subText: textMap['NetorkChangeToKlaytnMainnet'], subTextColor: '#666' }));
            setIsWhiteListed(false);
        }
        if (step === 4) {
            setIsWhiteListed(((_a = getMerkleProof(address)) === null || _a === void 0 ? void 0 : _a.length) > 0);
            setMessage(React.createElement("div", null,
                React.createElement(FaPaw, null),
                textMap['PressButtonAndMint']));
            setButton(React.createElement(React.Fragment, null,
                React.createElement(SimpleButton, { onClick: function () { return publicMintClick(); }, type: 'gray', text: textMap['MintPagePublicMintButton'], textColor: '#000', subText: '90 KLAY', subTextColor: '#000', width: 250, noMouseOver: true }),
                React.createElement(SimpleButton, { onClick: function () { return payByTokenMintClick(); }, type: 'orange', text: textMap['PayByTokenMint'], textColor: '#000', subText: '10K FEED', subTextColor: '#000', width: 250 })));
        }
    }, [step, address, networkId]);
    return (React.createElement(MintInnerContainerStyle, null,
        React.createElement(SlideContainer, null,
            React.createElement("img", { src: SlidesImg1Path, alt: 'slides' }),
            React.createElement("img", { src: SlidesImg2Path, alt: 'slides' }),
            React.createElement("img", { src: SlidesImg3Path, alt: 'slides' }),
            React.createElement("img", { src: SlidesImg4Path, alt: 'slides' })),
        React.createElement(ActionWrapper, null,
            React.createElement(ActionContainer, null,
                React.createElement(MintGuideTitle, null, textMap['PrepareMint']),
                React.createElement(MintGuide, null,
                    React.createElement("div", { style: step === 1 ? { color: 'yellow' } : null },
                        "\u2460 ",
                        textMap['WalletInstall']),
                    React.createElement("div", { style: step === 2 ? { color: 'yellow' } : null },
                        "\u2461 ",
                        textMap['WalletConnect']),
                    React.createElement("div", { style: step === 3 ? { color: 'yellow' } : null },
                        "\u2462 ",
                        textMap['NetorkChangeToKlaytnMainnet']),
                    React.createElement("div", { style: step === 4 ? { color: 'yellow' } : null },
                        "\u2463 ",
                        textMap['MainContentMintBtn'])),
                React.createElement(NotificationContainer, null,
                    React.createElement("h1", { style: { lineHeight: 1.3 } }, message),
                    React.createElement(Address, null, address ?
                        React.createElement(React.Fragment, null,
                            React.createElement(FaWallet, { style: { marginRight: 10 } }),
                            React.createElement("span", null, address)) :
                        '')),
                React.createElement(MintButtonWrapper, null,
                    isWhiteListed &&
                        React.createElement(WhiteListMintContainer, null,
                            React.createElement("h1", null, textMap['WhiteListDetected']),
                            React.createElement(SimpleButton, { onClick: function () { return preMintClick(); }, type: 'orange', text: textMap['WhiteListMinting'], subText: '45 KLAY', subTextColor: '#ece2b9' })),
                    button),
                React.createElement(Description, null,
                    React.createElement("div", null,
                        React.createElement(MdInfo, null),
                        " ",
                        textMap['MintDesc1']),
                    isWhiteListed && React.createElement("div", null,
                        React.createElement(MdInfo, null),
                        " ",
                        React.createElement("span", null, textMap['WhiteListLimit3'])),
                    React.createElement("div", null,
                        React.createElement(MdInfo, null),
                        " ",
                        React.createElement("span", null, textMap['PublicSaleLimit5'])))))));
};
var WhiteListMintContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: white;\n  text-align: center;\n  >h1 {\n    font-size: 19px;\n    color: yellow;\n    margin: 5px 0 10px 0;\n  }\n  margin-bottom: 10px;\n"], ["\n  color: white;\n  text-align: center;\n  >h1 {\n    font-size: 19px;\n    color: yellow;\n    margin: 5px 0 10px 0;\n  }\n  margin-bottom: 10px;\n"])));
var MintGuideTitle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: yellow;\n  font-size: 24px;\n  font-family: 'BMJUA';\n  margin: 30px 20px 10px 20px;\n"], ["\n  color: yellow;\n  font-size: 24px;\n  font-family: 'BMJUA';\n  margin: 30px 20px 10px 20px;\n"])));
var MintGuide = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: inline-block;\n  color: white;\n  line-height: 2;\n  padding: 10px 20px 30px 20px;\n"], ["\n  display: inline-block;\n  color: white;\n  line-height: 2;\n  padding: 10px 20px 30px 20px;\n"])));
var SlideContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n  width: 90%;\n  margin: auto;\n  text-align: center;\n  img {\n    width: 40%;\n    margin: 1%;\n    border-radius: 20px;\n  }\n"], ["\n  position: relative;\n  width: 90%;\n  margin: auto;\n  text-align: center;\n  img {\n    width: 40%;\n    margin: 1%;\n    border-radius: 20px;\n  }\n"])));
var ActionWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n"], ["\n  position: relative;\n  width: 100%;\n"])));
var ActionContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: relative;\n  width: 420px;\n  max-width: 100%;\n  margin: auto;\n"], ["\n  position: relative;\n  width: 420px;\n  max-width: 100%;\n  margin: auto;\n"])));
var MintInnerContainerStyle = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  ", "\n  ", "\n"], ["\n  display: flex;\n  ", "\n  ", "\n"])), styleMinWidth(767, "\n    flex-direction: row;\n  "), styleMaxWidth(767, "\n    flex-direction: column;\n  "));
var Address = styled.pre(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  text-overflow: ellipsis;\n  overflow: hidden;\n  padding: 10px 20px 30px 20px;\n  color: #ccc;\n"], ["\n  text-overflow: ellipsis;\n  overflow: hidden;\n  padding: 10px 20px 30px 20px;\n  color: #ccc;\n"])));
var MintButtonWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  text-align: center;\n"], ["\n  text-align: center;\n"])));
var MintButton = styled.button(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  padding: 10px;\n  width: 260px;\n  border: 0;\n  background: white;\n  border-radius: 15px;\n  h1 {\n    font-size: 24px;\n  }\n  h2 {\n    font-size: 15px;\n    font-weight: normal;\n  }\n  user-select: none;\n  cursor: pointer;\n  &:hover {\n    opacity: 0.5;\n  }\n  transition: opacity 100ms ease;\n"], ["\n  padding: 10px;\n  width: 260px;\n  border: 0;\n  background: white;\n  border-radius: 15px;\n  h1 {\n    font-size: 24px;\n  }\n  h2 {\n    font-size: 15px;\n    font-weight: normal;\n  }\n  user-select: none;\n  cursor: pointer;\n  &:hover {\n    opacity: 0.5;\n  }\n  transition: opacity 100ms ease;\n"])));
var NotificationContainer = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  font-size: 20px;\n  color: white;\n  h1 {\n    text-align: center;\n  }\n"], ["\n  font-size: 20px;\n  color: white;\n  h1 {\n    text-align: center;\n  }\n"])));
var Description = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  margin-top: 30px;\n  color: #f2a6a6;\n  margin-left: 20px;\n  div {\n    display: flex;\n    align-items: center;\n    line-height: 1.3;\n    margin: 7px 0;\n  }\n  svg {\n    margin-right: 10px;\n  }\n"], ["\n  margin-top: 30px;\n  color: #f2a6a6;\n  margin-left: 20px;\n  div {\n    display: flex;\n    align-items: center;\n    line-height: 1.3;\n    margin: 7px 0;\n  }\n  svg {\n    margin-right: 10px;\n  }\n"])));
export default MintInnerContainer;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
