var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import CometImgPath from 'resource/etc/comet.png';
var CometSpriteObj = /** @class */ (function () {
    function CometSpriteObj(data) {
        this.x = data.x;
        this.y = data.y;
        this.size = data.size;
        this.duration = data.duration;
        this.key = data.key;
        this.life = data.duration;
    }
    return CometSpriteObj;
}());
var counter = 0;
var Comet = function (_a) {
    var parent = _a.parent;
    var _b = useState(0), screenWidth = _b[0], setScreenWidth = _b[1];
    var _c = useState(0), screenHeight = _c[0], setScreenHeight = _c[1];
    var _d = useState([]), cometList = _d[0], setCometList = _d[1];
    function generateComet() {
        counter += 1;
        var obj = new CometSpriteObj({
            x: Math.random() * window.innerWidth - 80,
            y: Math.random() * (window.innerHeight * 0.6),
            size: Math.random() * 40 + 30,
            duration: Math.random() * 2000 + 2000,
            key: counter
        });
        return obj;
    }
    function tick() {
        var newList = cometList;
        var isParentOnScreen = (parent.current.offsetTop + parent.current.offsetHeight > window.scrollY);
        if (isParentOnScreen) {
            newList.push(generateComet());
        }
        var leftId = Math.max(0, newList.length - 10);
        setCometList(__spreadArray([], newList.slice(leftId, newList.length), true));
    }
    useEffect(function () {
        function handleResize() {
            setScreenWidth(parent.current.clientWidth);
            setScreenHeight(parent.current.clientHeight);
        }
        setTimeout(function () { return handleResize(); }, 500);
        window.addEventListener('resize', handleResize);
        var intv = setInterval(tick, 200);
        return function () {
            window.removeEventListener('resize', handleResize);
            clearInterval(intv);
        };
    }, []);
    return (React.createElement(CometWrapper, { width: screenWidth, height: screenHeight }, cometList.map(function (comet) { return React.createElement(CometSprite, { key: "comet".concat(comet.key), src: CometImgPath, comet: comet }); })));
};
var CometWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: ", "px;\n  height: ", "px;\n  overflow: hidden;\n  user-select: none;\n  z-index: -1;\n"], ["\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: ", "px;\n  height: ", "px;\n  overflow: hidden;\n  user-select: none;\n  z-index: -1;\n"])), function (props) { return props.width || 0; }, function (props) { return props.height || 0; });
var CometSprite = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: fixed;\n  left: ", "px;\n  top: ", "px;\n  width: ", "px;\n  height: ", "px;\n  transform: translate(0, 0);\n  animation: slidein ", "ms forwards;\n"], ["\n  position: fixed;\n  left: ", "px;\n  top: ", "px;\n  width: ", "px;\n  height: ", "px;\n  transform: translate(0, 0);\n  animation: slidein ", "ms forwards;\n"])), function (props) { return props.comet.x; }, function (props) { return props.comet.y; }, function (props) { return props.comet.size; }, function (props) { return props.comet.size; }, function (props) { return props.comet.duration; });
export default Comet;
var templateObject_1, templateObject_2;
