var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from 'react';
import styled from 'styled-components';
import { VscChromeClose } from 'react-icons/vsc';
import { PopupContext, TextMapContext } from 'src/context';
import { useContext } from 'react';
var MintFailPopup = function (_a) {
    var whiteListDesc = _a.whiteListDesc, payByToken = _a.payByToken;
    var setPopup = useContext(PopupContext);
    var _b = useContext(TextMapContext), textMap = _b[0], locale = _b[1];
    return (React.createElement(MintFailPopupStyle, null,
        React.createElement(CloseButtonWrapper, { onClick: function () { return setPopup(null); } },
            React.createElement(VscChromeClose, { color: '#000' })),
        React.createElement("h1", null, textMap['MintFailed']),
        React.createElement("h2", null, textMap['CheckMintFailedReason']),
        React.createElement("div", null,
            whiteListDesc && React.createElement("div", null,
                "- ",
                textMap['NotWhiteListedWallet']),
            React.createElement("div", null,
                "- ",
                textMap['UserTransactionCanceled']),
            !payByToken && React.createElement("div", null,
                "- ",
                textMap['ExceededMintingLimit']),
            payByToken ? React.createElement("div", null,
                "- ",
                textMap['LackOfFeedBalance'])
                : React.createElement("div", null,
                    "- ",
                    textMap['LackOfMintingClaimBalance']))));
};
var CloseButtonWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  right: 20px;\n  top: 20px;\n  font-size: 32px;\n  cursor: pointer;\n"], ["\n  position: absolute;\n  right: 20px;\n  top: 20px;\n  font-size: 32px;\n  cursor: pointer;\n"])));
var MintFailPopupStyle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  background: white;\n  padding: 30px;\n  border-radius: 5;\n  h1 {\n    font-size: 30px;\n  }\n  h2 {\n    padding: 10px 0 15px;\n    font-size: 20px;\n  }\n  div {\n    line-height: 1.3;\n  }\n"], ["\n  position: relative;\n  background: white;\n  padding: 30px;\n  border-radius: 5;\n  h1 {\n    font-size: 30px;\n  }\n  h2 {\n    padding: 10px 0 15px;\n    font-size: 20px;\n  }\n  div {\n    line-height: 1.3;\n  }\n"])));
export default MintFailPopup;
var templateObject_1, templateObject_2;
