var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from 'react';
import { useContext } from 'react';
import { styleMaxWidth, styleMinWidth } from 'src/common/styleUtil';
import { TextMapContext } from 'src/context';
import styled from 'styled-components';
var IMG_BASE_URL = process.env.IMG_BASE_URL;
var FeedingUnregisteredItem = function (_a) {
    var tokenId = _a.tokenId, onRegisterClick = _a.onRegisterClick;
    var _b = useContext(TextMapContext), textMap = _b[0], locale = _b[1];
    return (React.createElement(FeedingUnregisteredItemWrapper, null,
        React.createElement(ItemImage, { src: "".concat(IMG_BASE_URL).concat(tokenId, ".png"), alt: "unregistered nft image" }),
        React.createElement(ItemRegisterButton, { onClick: function () { return onRegisterClick(tokenId); } }, textMap['GetGrants'])));
};
var FeedingUnregisteredItemWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  display: inline-flex;\n  flex-direction: column;\n  ", "\n  ", "\n"], ["\n  position: relative;\n  display: inline-flex;\n  flex-direction: column;\n  ", "\n  ", "\n"])), styleMinWidth(767, "\n    width: 12%;\n    padding: 2% 0.5%;\n  "), styleMaxWidth(767, "\n    width: 44%;\n    padding: 2% 0.5%;\n  "));
var ItemImage = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: auto;\n  border-radius: 15px 15px 0 0;\n"], ["\n  width: 100%;\n  height: auto;\n  border-radius: 15px 15px 0 0;\n"])));
var ItemRegisterButton = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  padding: 10px 0;\n  border-radius: 0 0 15px 15px;\n  border: 0;\n  background: #002bff;\n  color: rgb(254 248 54);\n  font-family: 'BMJUA';\n  font-size: 23px;\n  word-break: keep-all;\n  cursor: pointer;\n  &:hover {\n    color: black;\n  }\n"], ["\n  width: 100%;\n  padding: 10px 0;\n  border-radius: 0 0 15px 15px;\n  border: 0;\n  background: #002bff;\n  color: rgb(254 248 54);\n  font-family: 'BMJUA';\n  font-size: 23px;\n  word-break: keep-all;\n  cursor: pointer;\n  &:hover {\n    color: black;\n  }\n"])));
export default FeedingUnregisteredItem;
var templateObject_1, templateObject_2, templateObject_3;
