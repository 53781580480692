var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import { AddressContext, FeedBalanceContext, NetworkIdContext, PopupContext, TextMapContext, Web3Context } from 'src/context';
import { VscChromeClose } from 'react-icons/vsc';
import { FaWallet, FaPaw } from 'react-icons/fa';
import { GiDogBowl } from 'react-icons/gi';
import { MdHome } from 'react-icons/md';
import WalletSelectPopup from 'src/component/WalletSelectPopup';
import { useNavigate } from 'react-router';
var NETWORK_ID = process.env.NETWORK_ID;
var Hamburger = function (_a) {
    var active = _a.active, setActive = _a.setActive;
    var setPopup = useContext(PopupContext);
    var web3Service = useContext(Web3Context)[0];
    var navigate = useNavigate();
    var _b = useContext(AddressContext), address = _b[0], setAddress = _b[1];
    var _c = useContext(NetworkIdContext), networkId = _c[0], setNetworkId = _c[1];
    var _d = useContext(FeedBalanceContext), feedBalance = _d[0], setFeedBalance = _d[1];
    return (React.createElement(TextMapContext.Consumer, null, function (_a) {
        var textMap = _a[0], locale = _a[1];
        return (React.createElement(React.Fragment, null,
            React.createElement(GlobalDark, { active: active, onClick: function () { return setActive(false); } }),
            React.createElement(HamburgerWrapper, { active: active },
                React.createElement(CloseButtonWrapper, { onClick: function () { return setActive(false); } },
                    React.createElement(VscChromeClose, { color: '#fff' })),
                React.createElement(ButtonContainer, null,
                    React.createElement(ButtonItem, { onClick: function () {
                            navigate("/".concat(locale));
                            window.scrollTo(0, 0);
                            setActive(false);
                        } },
                        React.createElement(ItemContainer, null,
                            React.createElement(MdHome, { color: "white", fontSize: 28 })),
                        React.createElement(ButtonTextContainer, null,
                            React.createElement("h1", null, textMap['CommonHome']))),
                    React.createElement(ButtonItem, { onClick: function () { return setPopup(React.createElement(WalletSelectPopup, { closeFunc: function () { return setPopup(null); } })); } },
                        React.createElement(ItemContainer, null,
                            React.createElement(FaWallet, { color: "white", fontSize: 28 })),
                        React.createElement(ButtonTextContainer, null,
                            React.createElement("h1", null, address || textMap['CommonConnectWallet']),
                            address && parseInt(NETWORK_ID) !== networkId
                                && React.createElement("h2", { style: { color: '#ffb0b0' } }, textMap['NetworkChangeRequest']))),
                    React.createElement(ButtonItem, { onClick: function () {
                            navigate("mint");
                            window.scrollTo(0, 0);
                            setActive(false);
                        } },
                        React.createElement(ItemContainer, null,
                            React.createElement(FaPaw, { color: "white", fontSize: 28 })),
                        React.createElement(ButtonTextContainer, null,
                            React.createElement("h1", null, textMap['MainContentMintBtn']),
                            React.createElement("h2", null, textMap['NextMintPreparing']))),
                    React.createElement(ButtonItem, { onClick: function () {
                            navigate("feed");
                            window.scrollTo(0, 0);
                            setActive(false);
                        } },
                        React.createElement(ItemContainer, null,
                            React.createElement(GiDogBowl, { color: "white", fontSize: 28 })),
                        React.createElement(ButtonTextContainer, null,
                            React.createElement("h1", null, textMap['CommonFEEDToken']),
                            React.createElement("h2", null,
                                textMap['Own'],
                                " ",
                                Math.floor(Number(feedBalance) / Math.pow(10, 16)) / 100,
                                " FEED")))))));
    }));
};
var ItemContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-right: 20px;\n"], ["\n  margin-right: 20px;\n"])));
var ButtonTextContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  overflow: hidden;\n  h1 {\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n  h2 {\n    margin-top: 10px;\n    font-size: 14px;\n    font-weight: lighter;\n    text-overflow: ellipsis;\n  }\n"], ["\n  overflow: hidden;\n  h1 {\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n  h2 {\n    margin-top: 10px;\n    font-size: 14px;\n    font-weight: lighter;\n    text-overflow: ellipsis;\n  }\n"])));
var ButtonItem = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  align-items: center;\n  padding: 20px;\n  &:hover {\n    background: #20284b;\n  }\n  font-size: 24px;\n  color: white;\n  cursor: pointer;\n  transition: background 100ms ease;\n"], ["\n  position: relative;\n  display: flex;\n  align-items: center;\n  padding: 20px;\n  &:hover {\n    background: #20284b;\n  }\n  font-size: 24px;\n  color: white;\n  cursor: pointer;\n  transition: background 100ms ease;\n"])));
var ButtonContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\n"], ["\n\n"])));
var HamburgerWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: fixed;\n  left: 0;\n  top: 0;\n  height: 100vh;\n  width: 300px;\n  background: #181f3f;\n  z-index: 1500;\n  overflow: hidden;\n  transform: translateX(", ");\n  transition: transform 100ms ease;\n  user-select: none;\n"], ["\n  position: fixed;\n  left: 0;\n  top: 0;\n  height: 100vh;\n  width: 300px;\n  background: #181f3f;\n  z-index: 1500;\n  overflow: hidden;\n  transform: translateX(", ");\n  transition: transform 100ms ease;\n  user-select: none;\n"])), function (props) { return props.active ? '0' : '-100%'; });
var GlobalDark = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: fixed;\n  width: 100vw;\n  height: 100vh;\n  background: #0000;\n  z-index: 1400;\n  display: ", ";\n"], ["\n  position: fixed;\n  width: 100vw;\n  height: 100vh;\n  background: #0000;\n  z-index: 1400;\n  display: ", ";\n"])), function (props) { return props.active ? 'block' : 'none'; });
var CloseButtonWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: white;\n  font-size: 32px;\n  text-align: right;\n  padding: 20px 20px 0 20px;\n  cursor: pointer;\n"], ["\n  color: white;\n  font-size: 32px;\n  text-align: right;\n  padding: 20px 20px 0 20px;\n  cursor: pointer;\n"])));
export default Hamburger;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
