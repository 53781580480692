var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { useState, useContext, useEffect } from 'react';
import { containerStyle, styleMaxWidth, styleMinWidth, titleText } from 'src/common/styleUtil';
import { AddressContext, FeedBalanceContext, TextMapContext, Web3Context } from 'src/context';
import styled from 'styled-components';
import FeedingItem from './FeedingItem';
import FeedingUnregisteredItem from './FeedingUnregisteredItem';
import SearchRestFeed from './SearchRestFeed';
var Feed = function (_a) {
    var web3Service = useContext(Web3Context)[0];
    var _b = useState([]), ownList = _b[0], setOwnList = _b[1];
    var address = useContext(AddressContext)[0];
    var _c = useState([]), registeredList = _c[0], setRegisteredList = _c[1];
    var _d = useContext(FeedBalanceContext), feedBalacne = _d[0], setFeedBalance = _d[1];
    var _e = useContext(TextMapContext), textMap = _e[0], locale = _e[1];
    useEffect(function () {
        (function () {
            return __awaiter(this, void 0, void 0, function () {
                var _ownList;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, web3Service.getOwnList(address)];
                        case 1:
                            _ownList = _a.sent();
                            setOwnList(_ownList);
                            return [2 /*return*/];
                    }
                });
            });
        })();
    }, [address]);
    function updateRegisteredList() {
        return __awaiter(this, void 0, void 0, function () {
            var _promises, _i, ownList_1, tokenId, _promise, _configList, _regieteredList, idx, _tid, _config;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _promises = [];
                        for (_i = 0, ownList_1 = ownList; _i < ownList_1.length; _i++) {
                            tokenId = ownList_1[_i];
                            _promise = web3Service.getFeedeeMap(tokenId);
                            _promises.push(_promise);
                        }
                        return [4 /*yield*/, Promise.all(_promises)];
                    case 1:
                        _configList = _a.sent();
                        _regieteredList = [];
                        for (idx in _configList) {
                            _tid = ownList[idx];
                            _config = _configList[idx];
                            if (parseInt(_config.startedAt) > 0) {
                                _regieteredList.push({
                                    tokenId: _tid,
                                    config: _config,
                                });
                            }
                        }
                        setRegisteredList(_regieteredList);
                        return [2 /*return*/];
                }
            });
        });
    }
    function registerToFeedMaster(tokenId) {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, web3Service.registerToFeedMaster(address, tokenId)];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, updateRegisteredList()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, res];
                }
            });
        });
    }
    function claimFeed(tokenId) {
        return __awaiter(this, void 0, void 0, function () {
            var res, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, web3Service.claimFeed(address, tokenId)];
                    case 1:
                        res = _b.sent();
                        _a = setFeedBalance;
                        return [4 /*yield*/, web3Service.getFeedBalance(address)];
                    case 2:
                        _a.apply(void 0, [_b.sent()]);
                        return [2 /*return*/, res];
                }
            });
        });
    }
    useEffect(function () {
        updateRegisteredList();
    }, [ownList]);
    var unregisteredItemList = [];
    ownList.forEach(function (tokenId) {
        if (!registeredList.map(function (row) { return row.tokenId; }).includes(tokenId)) {
            unregisteredItemList.push(React.createElement(FeedingUnregisteredItem, { key: "".concat(tokenId, "unregistered"), tokenId: tokenId, onRegisterClick: registerToFeedMaster }));
        }
    });
    var feedingItemList = [];
    registeredList.forEach(function (_a) {
        var tokenId = _a.tokenId, config = _a.config;
        feedingItemList.push(React.createElement(FeedingItem, { key: "".concat(tokenId, "preview"), tokenId: tokenId, config: config, onClaimClick: claimFeed }));
    });
    return (React.createElement(FeedWrapper, null,
        React.createElement(FeedContainer, null,
            React.createElement(FeedTitle, null,
                React.createElement("div", null, textMap['CommonFEEDToken'])),
            (unregisteredItemList === null || unregisteredItemList === void 0 ? void 0 : unregisteredItemList.length) > 0 &&
                React.createElement("div", { style: { padding: 30 } },
                    React.createElement(FeedSubTitle, null,
                        React.createElement("div", null, textMap['RegisterFeedMining'])),
                    React.createElement(FeedDesc, null,
                        React.createElement("li", null, textMap['RegisterFeedDesc1']),
                        React.createElement("li", null, textMap['RegisterFeedDesc2'])),
                    React.createElement(UnregisteredContainer, null, unregisteredItemList)),
            React.createElement("div", { style: { padding: 30 } },
                React.createElement(FeedSubTitle, null,
                    React.createElement("div", null, textMap['FeedGrantStatus'])),
                React.createElement(FeedDesc, null,
                    React.createElement("li", null, textMap['FeedGrantDesc1']),
                    React.createElement("li", null, textMap['FeedGrantDesc2']),
                    React.createElement("li", null, textMap['FeedGrantDesc3'])),
                React.createElement(FeedingItemContainer, null, (feedingItemList === null || feedingItemList === void 0 ? void 0 : feedingItemList.length) > 0 ? feedingItemList : React.createElement(EmptyView, null, textMap['NoSgjjFeedGrant']))),
            React.createElement("div", { style: { padding: 30 } },
                React.createElement(FeedSubTitle, null,
                    React.createElement("div", null, textMap['SearchRestFeedGrant'])),
                React.createElement(FeedDesc, null,
                    React.createElement("li", null, textMap['SearchRestFeedGrantDesc1'])),
                React.createElement(SearchRestFeed, null)))));
};
var EmptyView = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: #ffaeae;\n  font-size: 22px;\n  margin: 20px 0;\n"], ["\n  color: #ffaeae;\n  font-size: 22px;\n  margin: 20px 0;\n"])));
var FeedDesc = styled.ul(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-left: 20px;\n  color: white;\n  line-height: 1.5;\n  list-style: circle;\n  margin-bottom: 20px;\n"], ["\n  margin-left: 20px;\n  color: white;\n  line-height: 1.5;\n  list-style: circle;\n  margin-bottom: 20px;\n"])));
var FeedTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n  margin: 0 20px;\n"], ["\n  ", "\n  margin: 0 20px;\n"])), titleText);
var FeedSubTitle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n  color: rgb(254 248 54);\n  div {\n    font-size: 32px;\n  }\n"], ["\n  ", "\n  color: rgb(254 248 54);\n  div {\n    font-size: 32px;\n  }\n"])), titleText);
var UnregisteredContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n"])));
var FeedingItemContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n"])));
var FeedWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", "\n  ", "\n  background: #141458;\n"], ["\n  ", "\n  ", "\n  background: #141458;\n"])), styleMinWidth(767, "\n    padding: 150px 0 200px;\n  "), styleMaxWidth(767, "\n    padding: 100px 0 60px;\n  "));
var FeedContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), containerStyle);
export default Feed;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
