var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from 'react';
import { useContext } from 'react';
import { PopupContext } from 'src/context';
import styled from 'styled-components';
var Popup = function (props) {
    var popup = props.popup;
    var setPopup = useContext(PopupContext);
    if (!popup)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(Background, { onClick: function () { return setPopup(null); } }),
        React.createElement(PopupContainer, null, popup)));
};
var PopupContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  left: 50%;\n  top: 40%;\n  transform: translate(-50%, -50%);\n  z-index: 2100;\n"], ["\n  position: fixed;\n  left: 50%;\n  top: 40%;\n  transform: translate(-50%, -50%);\n  z-index: 2100;\n"])));
var Background = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: fixed;\n  z-index: 2000;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  background: black;\n  opacity: 0.5;\n"], ["\n  position: fixed;\n  z-index: 2000;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  background: black;\n  opacity: 0.5;\n"])));
export default Popup;
var templateObject_1, templateObject_2;
