import Whitelist from 'resource/contract/whitelist.json';
import { MerkleTree } from 'merkletreejs';
import keccak256 from 'keccak256';
window.Buffer = window.Buffer || require('buffer').Buffer;
function merkleTree() {
    var leafNodes = Whitelist.map(function (addr) { return keccak256(addr.toLowerCase()); });
    return new MerkleTree(leafNodes, keccak256, { sortPairs: true });
}
export function getMerkleRoot() {
    var rootHash = merkleTree().getRoot();
    return rootHash;
}
export function getMerkleProof(address) {
    if (!address)
        return null;
    var mt = merkleTree();
    var rootHash = merkleTree().getRoot();
    var proof = mt.getHexProof(keccak256(address.toLowerCase()));
    console.log('rootHash: ', rootHash.toString('hex'));
    return proof;
}
