var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from 'react';
import { useRef } from 'react';
import ProfileImgPath1 from 'resource/intro/intro4.png';
import ProfileImgPath2 from 'resource/intro/intro3.png';
import CoinImgPath from 'resource/intro/coin logo.png';
import styled from 'styled-components';
import { styleMinWidth, styleMaxWidth, containerStyle, titleText } from 'src/common/styleUtil';
import Comet from 'src/component';
import { BackgroundPallet, mainText } from 'src/common/styleUtil';
import ButtonContainer from './ButtonContainer';
import { TextMapContext } from 'src/context';
var IntroSection = function () {
    var ref = useRef();
    return (React.createElement(TextMapContext.Consumer, null, function (_a) {
        var textMap = _a[0], locale = _a[1];
        return React.createElement(IntroWrapper, { ref: ref },
            React.createElement(Comet, { parent: ref }),
            React.createElement(Snippet, null,
                React.createElement(SnippetTitle, null, textMap['IntroSectionStoryTitle']),
                React.createElement(SnippetContent, null,
                    React.createElement("p", null, textMap['IntroSectionStoryContent1']),
                    ' ',
                    React.createElement("p", null, textMap['IntroSectionStoryContent2']))),
            React.createElement(ButtonContainer, null),
            React.createElement(IntroContainer, null,
                React.createElement(IntroItem, null,
                    React.createElement(ProfileWrapper, null,
                        React.createElement("img", { src: ProfileImgPath2, alt: "nft-image" })),
                    React.createElement(IntroArticle, null,
                        React.createElement(IntroArticleTitle, null,
                            React.createElement("div", null, "SGJJ NFT"),
                            " ",
                            React.createElement("div", null, "Collection")),
                        React.createElement(IntroArticleSubTitle, null,
                            React.createElement("div", null, textMap['IntroSectionNftSubTitle'][0]),
                            " ",
                            React.createElement("div", null, textMap['IntroSectionNftSubTitle'][1])),
                        React.createElement(IntroArticleContent, { dangerouslySetInnerHTML: {
                                __html: textMap['IntroSectionNftContent'].toString()
                            } }))),
                React.createElement(IntroItem, null,
                    React.createElement(IntroArticle, { type: "middle" },
                        React.createElement(IntroArticleTitle, null,
                            React.createElement("div", null, "FEED"),
                            " ",
                            React.createElement("div", null, "Token")),
                        React.createElement(IntroArticleSubTitle, null,
                            React.createElement("div", null, textMap['IntroSectionFeedSubTitle'])),
                        React.createElement(ProfileWrapper, { width: 400, style: { margin: 'auto' } },
                            React.createElement("img", { src: CoinImgPath, style: { margin: 0 }, alt: 'coin image' })),
                        React.createElement(IntroArticleContent, { dangerouslySetInnerHTML: {
                                __html: textMap['IntroSectionFeedContent'].toString()
                            } }))),
                React.createElement(IntroItem, null,
                    React.createElement(IntroArticle, null,
                        React.createElement(IntroArticleTitle, null,
                            React.createElement("div", null, "Our"),
                            " ",
                            React.createElement("div", null, "Vision")),
                        React.createElement(IntroArticleSubTitle, null,
                            React.createElement("div", null, textMap['IntroSectionVisionSubTitle'][0]),
                            ' ',
                            React.createElement("div", null, textMap['IntroSectionVisionSubTitle'][1])),
                        React.createElement(IntroArticleContent, { dangerouslySetInnerHTML: {
                                __html: textMap['IntroSectionVisionContent'].toString()
                            } })),
                    React.createElement(ProfileWrapper, null,
                        React.createElement("img", { src: ProfileImgPath1, alt: "nft-image" })))));
    }));
};
var Snippet = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  ", "\n  margin: auto;\n  width: 1280px;\n  max-width: 90%;\n  ", "\n"], ["\n  ", "\n  ", "\n  margin: auto;\n  width: 1280px;\n  max-width: 90%;\n  ", "\n"])), styleMinWidth(768, "\n    padding: 250px 0 50px 0;\n  "), styleMaxWidth(768, "\n    padding: 120px 0 0 0;\n  "), mainText);
var SnippetTitle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: rgb(254 248 54);\n  margin-bottom: 22px;\n  ", "\n  ", "\n  line-height: 1.5;\n  font-family: 'BMJUA';\n"], ["\n  color: rgb(254 248 54);\n  margin-bottom: 22px;\n  ", "\n  ", "\n  line-height: 1.5;\n  font-family: 'BMJUA';\n"])), styleMinWidth(768, "\n    font-size: 42px;\n  "), styleMaxWidth(768, "\n    font-size: 28px;\n  "));
var SnippetContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: #ddd;\n  line-height: 1.5;\n  font-family: inherit;\n  p {\n    ", "\n    ", "\n  }\n"], ["\n  color: #ddd;\n  line-height: 1.5;\n  font-family: inherit;\n  p {\n    ", "\n    ", "\n  }\n"])), styleMinWidth(768, "\n      display: inline;\n    "), styleMaxWidth(768, "\n      margin: 25px 0;\n    "));
var IntroWrapper = styled.section(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n  z-index: 5;\n  background: ", ";\n"], ["\n  position: relative;\n  z-index: 5;\n  background: ", ";\n"])), BackgroundPallet.intro);
var IntroItem = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", "\n  ", "\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  \n  ", "\n  ", "\n"], ["\n  ", "\n  ", "\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  \n  ", "\n  ", "\n"])), styleMinWidth(768, "\n    padding: 200px 10px;\n  "), styleMaxWidth(768, "\n    padding: 80px 0px 80px 0px;\n  "), styleMinWidth(768, "\n    flex-direction: row;\n    justify-content: space-evenly;\n  "), styleMaxWidth(768, "\n    flex-direction: column;\n  "));
var IntroContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), containerStyle);
var ProfileWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  text-align: center;\n  img {\n    width: ", "px;\n    margin: 15px 0;\n    max-width: 90%;\n  }\n  ", "\n  ", "\n"], ["\n  text-align: center;\n  img {\n    width: ", "px;\n    margin: 15px 0;\n    max-width: 90%;\n  }\n  ", "\n  ", "\n"])), function (props) { return props.width || 600; }, styleMinWidth(768, "\n    max-width: 40%;\n    img {\n      max-width: 90%;\n    }\n  "), styleMaxWidth(768, "\n    img {\n      max-width: 90%;\n    }\n  "));
var IntroArticle = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  text-align: center;\n  position: relative;\n  ", "\n  ", "\n  ", "\n"], ["\n  text-align: center;\n  position: relative;\n  ", "\n  ", "\n  ", "\n"])), function (props) { return props.type === 'middle' && "\n    margin: auto;\n  "; }, styleMinWidth(768, "\n    max-width: 50%;\n  "), styleMaxWidth(768, "\n    padding: 0 20px;\n  "));
var IntroArticleContent = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), mainText);
var IntroArticleTitle = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), titleText);
var IntroArticleSubTitle = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  color: black;\n  margin-bottom: 20px;\n  text-align: center;\n  background: #FEF836;\n  border-radius: 1em;\n  display: inline-block;\n  div {\n    display: inline-block;\n    font-family: 'KOTRALEAP';\n  }\n  ", "\n  ", "\n"], ["\n  color: black;\n  margin-bottom: 20px;\n  text-align: center;\n  background: #FEF836;\n  border-radius: 1em;\n  display: inline-block;\n  div {\n    display: inline-block;\n    font-family: 'KOTRALEAP';\n  }\n  ", "\n  ", "\n"])), styleMinWidth(768, "\n    font-size: 28px;\n    padding: 5px 10px;\n  "), styleMaxWidth(768, "\n    font-size: 22px;\n    padding: 5px 10px;\n  "));
export default IntroSection;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
