var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { toHex } from "src/common";
import ContractInfoBaobab from 'resource/contract/contractInfo_baobab.json';
import ContractInfoMainnet from 'resource/contract/contractInfo_mainnet.json';
import MetamaskService from './metamask';
import KaikasService from './kaikas';
import { getMerkleProof } from "src/common/merkle";
var _a = process.env, RPC_URL = _a.RPC_URL, CHAIN_NAME = _a.CHAIN_NAME;
var ContractInfo = CHAIN_NAME === 'MAINNET' ? ContractInfoMainnet : ContractInfoBaobab;
var Web3Service = /** @class */ (function () {
    function Web3Service() {
        this.web3 = new window.Web3(RPC_URL);
        this.web3.sgjj = new this.web3.eth.Contract(ContractInfo['SGJJ'].abi, ContractInfo['SGJJ'].address);
        this.web3.sgjjService = new this.web3.eth.Contract(ContractInfo['SGJJService'].abi, ContractInfo['SGJJService'].address);
        this.web3.feed = new this.web3.eth.Contract(ContractInfo['Feed'].abi, ContractInfo['Feed'].address);
        this.web3.feedMaster = new this.web3.eth.Contract(ContractInfo['FeedMaster'].abi, ContractInfo['FeedMaster'].address);
        this.wallet = null;
        this.provider = null;
    }
    Web3Service.prototype.isWalletInstalled = function () {
        if (window.ethereum)
            return true;
        if (window.klaytn)
            return true;
        return false;
    };
    Web3Service.prototype.connectKaikas = function (setAddress) {
        return __awaiter(this, void 0, void 0, function () {
            var addr;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!window.klaytn)
                            return [2 /*return*/, Promise.reject()];
                        return [4 /*yield*/, window.klaytn.enable()];
                    case 1:
                        addr = _a.sent();
                        this.wallet = new KaikasService(ContractInfo);
                        window.klaytn.on('accountsChanged', function (accounts) {
                            setAddress(accounts[0]);
                        });
                        this.provider = window.klaytn;
                        localStorage.setItem('walletType', 'kaikas');
                        return [2 /*return*/, addr[0]];
                }
            });
        });
    };
    Web3Service.prototype.connectMetamask = function (setAddress) {
        return __awaiter(this, void 0, void 0, function () {
            var addr;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!window.ethereum) {
                            return [2 /*return*/, Promise.reject()];
                        }
                        return [4 /*yield*/, window.ethereum.request({ method: 'eth_requestAccounts' })];
                    case 1:
                        addr = _a.sent();
                        this.wallet = new MetamaskService(ContractInfo);
                        window.ethereum.on('accountsChanged', function (accounts) {
                            setAddress(accounts[0]);
                        });
                        this.provider = window.ethereum;
                        localStorage.setItem('walletType', 'metamask');
                        return [2 /*return*/, addr[0]];
                }
            });
        });
    };
    Web3Service.prototype.getWalletType = function () {
        var _a;
        return ((_a = this.wallet) === null || _a === void 0 ? void 0 : _a.type) || null;
    };
    Web3Service.prototype.getChainId = function () {
        if (!this.provider)
            return -1;
        return parseInt(this.provider.networkVersion, 10);
    };
    Web3Service.prototype.getOwnList = function (address) {
        return __awaiter(this, void 0, void 0, function () {
            var balance, promises, i, _promise, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.wallet || !address)
                            return [2 /*return*/, []];
                        return [4 /*yield*/, this.wallet.sgjj.methods.balanceOf(address).call()];
                    case 1:
                        balance = _a.sent();
                        promises = [];
                        for (i = 0; i < balance; i++) {
                            _promise = this.wallet.sgjj.methods.tokenOfOwnerByIndex(address, i).call();
                            promises.push(_promise);
                        }
                        return [4 /*yield*/, Promise.all(promises)];
                    case 2:
                        res = _a.sent();
                        return [2 /*return*/, res];
                }
            });
        });
    };
    Web3Service.prototype.getClaimableFeed = function (tokenId) {
        return __awaiter(this, void 0, void 0, function () {
            var amount, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.web3.feedMaster.methods.claimableFeed(ContractInfo['SGJJ'].address, tokenId).call()];
                    case 1:
                        amount = _a.sent();
                        return [2 /*return*/, parseInt(amount)];
                    case 2:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [2 /*return*/, -1];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Web3Service.prototype.getFeedeeMap = function (tokenId) {
        return __awaiter(this, void 0, void 0, function () {
            var config, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.web3.feedMaster.methods.feedeeMap(ContractInfo['SGJJ'].address, tokenId).call()];
                    case 1:
                        config = _a.sent();
                        return [2 /*return*/, config];
                    case 2:
                        e_2 = _a.sent();
                        return [2 /*return*/, -1];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Web3Service.prototype.registerToFeedMaster = function (address, tokenId) {
        return __awaiter(this, void 0, void 0, function () {
            var transaction, e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.wallet)
                            return [2 /*return*/, false];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.wallet.feedMaster.methods.register(ContractInfo['SGJJ'].address, tokenId)];
                    case 2:
                        transaction = _a.sent();
                        return [4 /*yield*/, this.wallet.send(transaction, {
                                from: address,
                            })];
                    case 3:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 4:
                        e_3 = _a.sent();
                        console.log("not registable ".concat(tokenId));
                        return [2 /*return*/, false];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    Web3Service.prototype.claimFeed = function (address, tokenId) {
        return __awaiter(this, void 0, void 0, function () {
            var transaction, e_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.wallet)
                            return [2 /*return*/, false];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.wallet.feedMaster.methods.claimFeed(ContractInfo['SGJJ'].address, tokenId)];
                    case 2:
                        transaction = _a.sent();
                        return [4 /*yield*/, this.wallet.send(transaction, {
                                from: address,
                            })];
                    case 3:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 4:
                        e_4 = _a.sent();
                        console.log("claim error ".concat(tokenId));
                        return [2 /*return*/, false];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    Web3Service.prototype.changeNetwork = function (netId) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.provider.request({
                                method: 'wallet_switchEthereumChain',
                                params: [{ chainId: toHex(netId) }]
                            })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/, true];
                    case 2:
                        _a = _b.sent();
                        return [2 /*return*/, false];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Web3Service.prototype.getPublicSaleConfig = function () {
        return __awaiter(this, void 0, void 0, function () {
            var obj;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.wallet.sgjjService.methods.publicSale().call()];
                    case 1:
                        obj = _a.sent();
                        return [2 /*return*/, obj];
                }
            });
        });
    };
    Web3Service.prototype.getPreSaleConfig = function () {
        return __awaiter(this, void 0, void 0, function () {
            var obj;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.wallet.sgjjService.methods.preSale().call()];
                    case 1:
                        obj = _a.sent();
                        return [2 /*return*/, obj];
                }
            });
        });
    };
    Web3Service.prototype.getPayByTokenSaleConfig = function () {
        return __awaiter(this, void 0, void 0, function () {
            var obj;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.wallet.sgjjService.methods.payByTokenSale().call()];
                    case 1:
                        obj = _a.sent();
                        return [2 /*return*/, obj];
                }
            });
        });
    };
    Web3Service.prototype.getFeedBalance = function (address) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.wallet.feed.methods.balanceOf(address).call()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Web3Service.prototype.publicSaleMint = function (address) {
        return __awaiter(this, void 0, void 0, function () {
            var transaction;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.wallet.sgjjService.methods.publicSaleMint()];
                    case 1:
                        transaction = _a.sent();
                        return [4 /*yield*/, this.wallet.send(transaction, {
                                from: address,
                                value: parseInt(process.env.PUBLIC_SALE_PRICE) * Math.pow(10, 18)
                            })];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Web3Service.prototype.preSaleMint = function (address) {
        return __awaiter(this, void 0, void 0, function () {
            var transaction;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.wallet.sgjjService.methods.preSaleMint(getMerkleProof(address))];
                    case 1:
                        transaction = _a.sent();
                        return [4 /*yield*/, this.wallet.send(transaction, {
                                from: address,
                                value: parseInt(process.env.PRE_SALE_PRICE) * Math.pow(10, 18)
                            })];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Web3Service.prototype.payByTokenSaleMint = function (address) {
        return __awaiter(this, void 0, void 0, function () {
            var transaction, allowance, allowTransaction;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.wallet.sgjjService.methods.payByTokenSaleMint()];
                    case 1:
                        transaction = _a.sent();
                        return [4 /*yield*/, this.wallet.feed.methods.allowance(address, ContractInfo['SGJJService'].address).call()];
                    case 2:
                        allowance = _a.sent();
                        if (!(allowance < 10000 * Math.pow(10, 18))) return [3 /*break*/, 4];
                        allowTransaction = this.wallet.feed.methods.approve(ContractInfo['SGJJService'].address, toHex(1000000 * Math.pow(10, 18)));
                        return [4 /*yield*/, this.wallet.send(allowTransaction, { from: address })];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [4 /*yield*/, this.wallet.send(transaction, {
                            from: address,
                        })];
                    case 5: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return Web3Service;
}());
export { Web3Service };
