var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from 'react';
import IntroSection from './IntroSection';
import MainContent from './MainContent';
import RaceSection from './RaceSection';
import RoadmapSection from './RoadmapSection';
import AboutSection from './AboutSection';
import FaqSection from './FaqSection';
import styled from 'styled-components';
import { styleMinWidth, styleMaxWidth } from 'src/common/styleUtil';
var Home = function (_a) {
    return (React.createElement(HomeContainer, null,
        React.createElement(MainContent, null),
        React.createElement(IntroSection, null),
        React.createElement(RaceSection, null),
        React.createElement(RoadmapSection, null),
        React.createElement(FaqSection, null),
        React.createElement(AboutSection, null)));
};
var HomeContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 30px;\n  background: #0d2185;\n  ", "\n  ", "\n"], ["\n  font-size: 30px;\n  background: #0d2185;\n  ", "\n  ", "\n"])), styleMinWidth(767, "\n    padding-top: 70px;\n  "), styleMaxWidth(767, "\n    padding-top: 50px;\n  "));
export default Home;
var templateObject_1;
