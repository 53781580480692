var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from 'react';
import { useContext } from 'react';
import { TextMapContext } from 'src/context';
import RoadmapItem from './RoadmapItem';
import styled from 'styled-components';
import { containerStyle, BackgroundPallet, mainText, titleText } from 'src/common/styleUtil';
import Cloud from 'src/component/Cloud';
var RoadmapDesc = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: #111;\n  padding: 0 10px;\n  display: block;\n  line-height: 1.3;\n  b {\n    font-weight: bolder;\n    color: #000;\n  }\n"], ["\n  color: #111;\n  padding: 0 10px;\n  display: block;\n  line-height: 1.3;\n  b {\n    font-weight: bolder;\n    color: #000;\n  }\n"])));
var RoadmapSection = function () {
    var _a = useContext(TextMapContext), textMap = _a[0], locale = _a[1];
    var checkPoints = [0, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    var message = [];
    for (var i = 0; i < 10; i++) {
        message.push({
            goal: checkPoints[i],
            desc: React.createElement(RoadmapDesc, { dangerouslySetInnerHTML: {
                    __html: textMap["RoadmapItem".concat(i + 1)].toString()
                } })
        });
    }
    return (React.createElement(RoadmapWrapper, null,
        React.createElement(Cloud, null),
        React.createElement(RoadmapTopWrapper, null),
        React.createElement(RoadmapContainer, null,
            React.createElement(RoadmapTitle, null,
                React.createElement("div", null, "Roadmap")),
            React.createElement(RoadmapSubTitle, null,
                textMap['RoadmapContent'],
                ' ',
                "(",
                React.createElement("a", { href: "https://public.countrymixdog.io/document/roadmap_detail_".concat(locale, ".pdf"), target: "_blank" }, textMap['ViewDetail']),
                ")"),
            React.createElement("div", { className: 'roadmap__item-container' }, message.map(function (item) {
                return React.createElement(RoadmapItem, { key: item.goal + 'goal', goal: item.goal, desc: item.desc });
            })))));
};
var RoadmapTopWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 20%;\n  width: 100%;\n  position: absolute;\n  left: 0;\n  top: 0;\n  z-index: 6;\n  background: ", ";\n"], ["\n  height: 20%;\n  width: 100%;\n  position: absolute;\n  left: 0;\n  top: 0;\n  z-index: 6;\n  background: ", ";\n"])), BackgroundPallet.roadmapTop);
var RoadmapWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: ", ";\n  position: relative;\n  padding: 0 20px;\n"], ["\n  background: ", ";\n  position: relative;\n  padding: 0 20px;\n"])), BackgroundPallet.roadmap);
var RoadmapContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n  padding: 250px 0 350px;\n  display: relative;\n"], ["\n  ", "\n  padding: 250px 0 350px;\n  display: relative;\n"])), containerStyle);
var RoadmapTitle = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", "\n  margin-bottom: 20px;\n  text-shadow: 1px 1px 10px #000;\n"], ["\n  ", "\n  margin-bottom: 20px;\n  text-shadow: 1px 1px 10px #000;\n"])), titleText);
var RoadmapSubTitle = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: block;\n  margin-bottom: 30px;\n  text-shadow: 1px 1px 2px #333;\n  ", "\n  a {\n    color: #fffdbc;\n  }\n"], ["\n  display: block;\n  margin-bottom: 30px;\n  text-shadow: 1px 1px 2px #333;\n  ", "\n  a {\n    color: #fffdbc;\n  }\n"])), mainText);
export default RoadmapSection;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
