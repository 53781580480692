import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Navigate, Route, Routes } from 'react-router';
import App from './pages/App/index';
import './index.scss';
import { Web3Service } from './service/web3';
var locale = 'ko';
if (navigator.language === 'en-US') {
    locale = 'en';
}
function Redirect() {
    return (React.createElement(Navigate, { to: "/".concat(locale) }));
}
var web3Service = new Web3Service();
ReactDOM.render(React.createElement(BrowserRouter, null,
    React.createElement(Routes, null,
        React.createElement(Route, { index: true, element: React.createElement(Redirect, null) }),
        React.createElement(Route, { path: "/ko/*", element: React.createElement(App, { locale: "ko", web3Service: web3Service }) }),
        React.createElement(Route, { path: "/en/*", element: React.createElement(App, { locale: "en", web3Service: web3Service }) }))), document.getElementById('root'));
