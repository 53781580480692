var TextRawMap = {
    Search: {
        ko: '조회',
        en: 'Search'
    },
    NotAppliedGrantNFT: {
        ko: '등록되지 않은 NFT입니다',
        en: 'This is an unapplied NFT'
    },
    Claim: {
        ko: '획득하기',
        en: 'Claim'
    },
    Claimable: {
        ko: '획득 가능',
        en: 'Claimable'
    },
    GrantsLimit: {
        ko: '남은 지원금',
        en: 'Leftover'
    },
    HashPower: {
        ko: '해시파워',
        en: 'Hash Power'
    },
    GetGrants: {
        ko: '지원받기',
        en: 'Get grants'
    },
    SearchRestFeedGrant: {
        ko: 'FEED 남은 지원금 검색',
        en: 'Search for FEED status'
    },
    SearchRestFeedGrantDesc1: {
        ko: '토큰 아이디를 입력하여 남은 지원금을 검색합니다',
        en: 'Enter the token ID to retrieve the remaining grants'
    },
    NoSgjjFeedGrant: {
        ko: '지원 신청된 SGJJ가 없습니다',
        en: 'There are no SGJJs applied for grants'
    },
    FeedGrantStatus: {
        ko: 'FEED 지원금 현황',
        en: 'FEED Grants Status'
    },
    FeedGrantDesc1: {
        ko: '획득하기 버튼을 통해 지원 받은 FEED를 지갑으로 옮깁니다',
        en: 'Transfer the granted FEED by clicking the Claim button to your wallet',
    },
    FeedGrantDesc2: {
        ko: '하나의 NFT는 정해진 양의 FEED를 지원 받습니다',
        en: 'One NFT is supported by a fixed amount of FEED',
    },
    FeedGrantDesc3: {
        ko: 'NFT를 판매 또는 양도 하여도 남은 지원금 양은 변하지 않습니다',
        en: 'Selling or transferring NFTs does not change the amount of grants remaining',
    },
    RegisterFeedDesc1: {
        ko: '한 번만 지원금을 신청하면 FEED가 지속적으로 축적됩니다',
        en: 'Apply for grants only once and your FEED will accumulate over time',
    },
    RegisterFeedDesc2: {
        ko: '지원금을 신청하면 취소할 수 없습니다',
        en: 'Once apply for grants, you cannot cancel it',
    },
    RegisterFeedMining: {
        ko: 'FEED 지원 신청하기',
        en: 'Apply for FEED grants'
    },
    Own: {
        ko: '보유',
        en: 'holding',
    },
    PayByTokenNotStarted: {
        ko: 'FEED 결제가 일시중단 되었습니다',
        en: 'FEED payment paused'
    },
    PayByTokenMint: {
        ko: '민팅(FEED)',
        en: 'Minting(FEED)',
    },
    NetworkChangeRequest: {
        ko: '네트워크 변경 필요',
        en: 'Network change required',
    },
    ViewDetail: {
        ko: '자세히 보기',
        en: 'View Detail',
    },
    MintPagePublicMintButton: {
        ko: '퍼블릭 민팅',
        en: 'Public Minting',
    },
    WhiteListMintingNotStarted: {
        ko: '화이트리스트 민팅이 아직 시작되지 않았습니다',
        en: 'Whitelist minting not started yet'
    },
    PublicMintingNotStarted: {
        ko: '퍼블릭 민팅이 아직 시작되지 않았습니다',
        en: 'Public minting not started yet'
    },
    WhiteListDetected: {
        ko: '화이트리스트 반려인을 반견했당멍',
        en: 'Whitelist address identified arf',
    },
    WhiteListMinting: {
        ko: '화이트리스트 민팅',
        en: 'WhiteList Minting',
    },
    NextMintPreparing: {
        ko: '퍼블릭 세일을 준비중입니다',
        en: 'Public Sale TBA'
    },
    MintDesc1: {
        ko: '다음 민팅을 준비중입니다. 추후에 공지하겠습니다',
        en: 'Preparing the next Minting. TBA',
    },
    WhiteListLimit3: {
        ko: '화이트리스트 민팅은 3회 가능합니다',
        en: 'Whitelist minting permits 3 times',
    },
    PublicSaleLimit5: {
        ko: '퍼블릭 민팅은 지갑당 5회 가능합니다',
        en: 'Public minting permits 5 times per wallet',
    },
    MintFailed: {
        ko: '민팅 실패',
        en: 'Minting Failed',
    },
    CheckMintFailedReason: {
        ko: '민팅에 실패했습니다. 아래 사유를 확인해주세요',
        en: 'Minting has failed, check the reasons below',
    },
    NotWhiteListedWallet: {
        ko: '화이트리스트된 지갑이 아닙니다',
        en: 'Not a whitelisted wallet',
    },
    UserTransactionCanceled: {
        ko: '사용자가 트렌젝션을 취소했습니다',
        en: 'Transaction has been canceled by owner',
    },
    ExceededMintingLimit: {
        ko: '지갑당 민팅 가능한 개수를 초과했습니다',
        en: 'Mininting limit has reached ',
    },
    LackOfMintingClaimBalance: {
        ko: '보유 중인 클레이가 부족합니다',
        en: 'Not enough Klay holding in the wallet',
    },
    LackOfFeedBalance: {
        ko: '보유 중인 FEED가 부족합니다',
        en: 'Not enough FEED holding in the wallet',
    },
    PrepareMint: {
        ko: '단계별로 준비하기',
        en: 'Prepare Step By Step'
    },
    AddNetworkToMetamaskDirectly: {
        ko: '메타마스크에 클레이튼(ID: 1001) 네트워크를 추가해주세요',
        en: 'Add Network (ID: 1001) To Your Metamask'
    },
    PressButtonAndMint: {
        ko: '버튼을 눌러 분양하세요',
        en: 'Press Button For Minting'
    },
    NetorkChangeToKlaytnMainnet: {
        ko: '클레이튼 체인으로 전환',
        en: 'Change To Klaytn Mainnet'
    },
    NetworkChange: {
        ko: '클레이튼 체인으로 변경하기',
        en: 'Change Network'
    },
    ChangeNetworkKaikas: {
        ko: '카이카스 지갑에서 직접 네트워크를 변경 후 새로고침 해주세요',
        en: 'Directly Change Network Id with Kaikas and Refresh'
    },
    ChangeNetworkToMainnet: {
        ko: '클레이튼 체인으로 변경해주세요',
        en: 'Change Wallet Network Id To Klaytn Mainnet'
    },
    InstallWallet: {
        ko: '지갑을 설치해주세요',
        en: 'Install Wallet'
    },
    WalletInstall: {
        ko: '카이카스 설치하기',
        en: 'Install Kaikas'
    },
    LinkToInstallKaikas: {
        ko: '카이카스 지갑 설치 링크',
        en: 'Link To Install Kaikas'
    },
    ConnectWallet: {
        ko: '지갑을 연결해주세요',
        en: 'Connect Your Wallet'
    },
    WalletConnect: {
        ko: '지갑 연결하기',
        en: 'Connect Wallet'
    },
    CommonWhiteList: {
        ko: '화이트리스트',
        en: 'WhiteList'
    },
    CommonHome: {
        ko: '홈',
        en: 'Home',
    },
    CommonConnectWallet: {
        ko: '지갑 연결',
        en: 'Connect Wallet'
    },
    CommonKaikas: {
        ko: '카이카스',
        en: 'Kaikas'
    },
    CommonMetamask: {
        ko: '메타마스크',
        en: 'Metamask'
    },
    CommonPreparing: {
        ko: '준비중',
        en: 'Preparing'
    },
    CommonPreparingAlertMessage: {
        ko: '출시를 준비중입니다\nComing Soon! 🐶',
        en: 'Preparing for release\nComing Soon! 🐶',
    },
    CommonFEEDToken: {
        ko: 'FEED 토큰',
        en: 'FEED Token'
    },
    MainContentMintBtn: {
        ko: '민팅하기',
        en: 'Mint Now'
    },
    MainContentTitle: {
        ko: ['시고르', '자브종'],
        en: ['Sigor', 'JabuJong']
    },
    MainContentSubTitle: {
        ko: ['대한민국', '고급견종 NFT'],
        en: ['Korea\'s', 'Most Loyal Dog NFT']
    },
    IntroSectionStoryTitle: {
        ko: '"대한민국의 고급 견종 시고르자브종을 분양해가세요"',
        en: '"Adopt South Korea\'s most loyal dog"'
    },
    IntroSectionStoryContent1: {
        ko: '대한민국의 재개발 열풍으로 인해 한국의 고유한 고급 견종인 시고르자브종의 개체수가 급격하게 감소하고 있어요. 이러한 추세가 계속된다면 시고르자브종은 결국 멸종하고 말 거에요.',
        en: 'Due to the development of countryside towns in South Korea, the population of SigorJabuJong, a high-end dog breed from South Korea, may eventually become extinct.'
    },
    IntroSectionStoryContent2: {
        ko: '우리는 시고르자브종을 지키기 위해 최선을 다 하고 있지만 여러분들의 도움이 필요한 상태입니다. 시고르자브종을 분양해서 멸종의 위기로부터 보호해주세요!',
        en: 'We are doing everything we can to protect the SigorJabuJong, but we need your help. You can help save them from extinction by adopting a SigorJabuJong!'
    },
    IntroSectionNftSubTitle: {
        ko: ['"시고르자브종', '분양증서란?"'],
        en: ['"What is a SGJJ', 'sales certificate?"']
    },
    IntroSectionNftContent: {
        ko: '<strong>시고르자브종(SGJJ)</strong>은 대한민국 시골에서만 볼 수 있는 고급 믹스견을 모티브로 제작한 NFT 컬렉션입니다. <strong>스마트컨트렉트 기반 분양증서</strong>로써 다양한 플랫폼에서 쉽게 거래하거나 소장할 수 있습니다.',
        en: 'The <strong>SigorJabuJong(SGJJ)</strong> project is an NFT collection made with South Korea\'s one of a kind native best friend. As a smart contract-based sale certificate, they can be easily traded and held on diverse platforms. Adopt and care for a diverse selection of SigorJabuJong.'
    },
    IntroSectionFeedSubTitle: {
        ko: '"사료코인이란?"',
        en: '"What is FEED Coin?"',
    },
    IntroSectionFeedContent: {
        ko: 'FEED 토큰은 시고르자브종 소유자에게 주는 <strong>지원금</strong>입니다. 이 토큰을 이용하여 <strong>새로운 친구</strong>를 만나거나 <strong>다양한 활동</strong>에 참여할 수 있습니다. 토큰은 클레이튼 체인에서 발행됩니다. 토큰을 획득하기 위해 시고르자브종을 분양해 보세요.',
        en: 'FEED tokens are a <strong>subsidy given to the owners</strong> of SigorJabuJong. With these tokens, you can adopt a <strong>new friend</strong> and participate in <strong>various activities</strong>. Tokens will be issued on the Klaytn Chain. Adopt a SigorJabuJong and receive your tokens.'
    },
    IntroSectionVisionSubTitle: {
        ko: ['"SGJJ의', '목표는?"'],
        en: ['SGJJ’s', 'Vision?']
    },
    IntroSectionVisionContent: {
        ko: '시고르자브종은 시골에 놀러온 모든 이들을 맑은 미소로 맞이합니다. 이런 정겨운 모습 뒤에 가려졌지만 사실은 <strong>한국의 최고급 품종</strong>입니다. 시고르자브종의 이 엄청난 고귀함이 세계적인 인정을 받을 때까지 우리는 멈추지 않을 것입니다.',
        en: 'SigorJabuJong welcomes all who come to the countryside with a bright smile. Although mistaken as cute dogs, they are <strong>South Korea’s highest quality breed</strong>. We will not stop until the great nobility of SigorJabuJong is recognized worldwide.'
    },
    WalletGuideLinkDesc: {
        ko: 'SGJJ를 민팅하려면 <b>클레이튼</b>이 필요합니다',
        en: 'SGJJ needs <b>Klaytn</b> for Minting',
    },
    WalletGuideLinkText: {
        ko: '지갑 설정 가이드',
        en: 'Wallet Setup Guide'
    },
    WalletGuidePdfLink: {
        ko: 'https://public.countrymixdog.io/document/wallet_guide_ko.pdf',
        en: 'https://public.countrymixdog.io/document/wallet_guide_en.pdf'
    },
    ButtonContainerScollNote: {
        ko: '아래로 스크롤하여 더 알아보세요',
        en: 'Scroll down to learn more',
    },
    RaceTitle1: { ko: '불', en: 'Fire' },
    RaceTitle2: { ko: '물', en: 'Water' },
    RaceTitle3: { ko: '돌', en: 'Rock' },
    RaceTitle4: { ko: '로봇', en: 'Robot' },
    RaceTitle5: { ko: '풀', en: 'Grass' },
    RaceTitle6: { ko: '좀비', en: 'Zombie' },
    RaceTitle7: { ko: '유령', en: 'Ghost' },
    RaceTitle8: { ko: '해골', en: 'Skeleton' },
    RaceDescription1: {
        ko: '한국인들이 사랑하는 찜질방에서 볼 수 있는 대표적인 시고르자브종입니다. 높은 온도를 매우 즐기며 인간들과 식혜도 즐겨 마십니다.',
        en: 'An Iconic SigorJabuJong found in a Korean Sauna, which is loved by Koreans, and enjoys high temperatures while drinking sikhye with humans.'
    },
    RaceDescription2: {
        ko: '회를 썰어주면 너무 좋다고 날뛰는 시고르자브종입니다. 입맛이 고급스럽고 사람들과 잘 어울리는 매너견입니다.',
        en: 'These SigorJabuJongs are well-mannered with a luxurious taste and get along well with people; they will jump with joy when you cut sashimi for them to eat.'
    },
    RaceDescription3: {
        ko: '인삼을 캐러 다닐 때에 필수로 데려가야 할 시고르자브종입니다. 미네랄이 풍부한 흙에서 몸을 비비는 것을 좋아하며 땅에서 자라는 것은 무엇이든 찾아낼수 있습니다.',
        en: 'Essential when digging for ginseng, these SigorJabuJongs enjoy rubbing their bodies in soil with high mineral content and can sniff out anything that grows in the ground.'
    },
    RaceDescription4: {
        ko: '한국 최고의 AI가 탑재된 현대적인 시고르자브종으로. 강아지 털 알레르기 있으신 분들에게는 최고의 파트너가 되어 줄 것입니다.',
        en: 'Modernized SigorJabuJongs representing South Korea’s AI development, they will become the best partner for those who are allergic to dog furs.'
    },
    RaceDescription5: {
        ko: '과일과 채소에 관심이 많은 시고르자브종으로서 다양한 농산물에 대한 이해도가 높습니다. 농사에 많은 도움이 되는 친구입니다.',
        en: 'These SigorJabuJongs are helpful in the farm field because they have exceptional knowledge of various agricultural products.'
    },
    RaceDescription6: {
        ko: '강아지 펜데믹이 일어나면서 생긴 변종이지만, 꼬리를 흔들며 사람을 잘 따르는 시고르자브종입니다.',
        en: 'Although the virus pandemic caused a mutation, these SigorJabuJongs loyally follow people while happily wagging their tail.'
    },
    RaceDescription7: {
        ko: '버림을 받았음에도 주인을 애타게 기다리다 결국에는 유령이 된 시고르자브종입니다. 그럼에도 사람들에게 즐거움을 주는 것을 좋아합니다.',
        en: 'Despite being abandoned, these SigorJabuJongs eagerly waited for their master, eventually becoming a ghost, but they still love to make people happy.'
    },
    RaceDescription8: {
        ko: '주인이 걱정되어 죽음의 땅에서 돌아온 시고르자브종입니다. 주인을 지키기 위해 멀리서 지켜보고 있어요.',
        en: 'Worried about the well-being of their owners, these SigorJabuJongs have returned from the land of the dead and observe their owners from a distance.'
    },
    RaceContent: {
        ko: '총 30종류의 돌연변이가 있습니다. <strong>희귀도</strong>가 각각 다르며, 성격과 취향도 가지각색입니다. <br />나와 잘 맞는 시고르자브종을 찾아보세요!',
        en: 'There are a total of 30 types of mutations. Each has a different <strong>rarity</strong>, personality, and style. <br />Find a SigorJabuJong that suits you!'
    },
    RoadmapContent: {
        ko: '시고르자브종이 분양될 때마다 한 단계씩 더 발전할 거에요. 모든 시고르자브종이 행복해질 수 있게 도와주세요!',
        en: 'Each time a SigorJabuJong is adopted, we improve one step further. Let’s help all SigorJabuJongs become happy!',
    },
    RoadmapItem1: {
        ko: '시골마을에 <b>시고르자브종</b>이 나타났어요',
        en: '<b>SigorJabuJongs</b> have appeared in a countryside town',
    },
    RoadmapItem2: {
        ko: '시고르자브종을 위한 <b>계모임(커뮤니티 월렛)</b>을 만들 거에요',
        en: 'A community <b>trust fund (community wallet)</b> will be created for SigorJabuJong',
    },
    RoadmapItem3: {
        ko: '시고르자브종 거래 수수료의 <b>25%를 곗돈</b>에 넣을 거에요',
        en: '<b>25% of transaction fees</b> will be sent to community trust fund',
    },
    RoadmapItem4: {
        ko: '홈페이지 업데이트를 통해 주인님들이 시고르자브종을 살펴볼 수 있는 <b>강아지 집</b>을 만들 거에요',
        en: 'By updating the website, make a <b>dog house</b> for owners to observe their SigorJabuJong',
    },
    RoadmapItem5: {
        ko: '성장 방향을 투표 및 소통을 할 수 있는 <b>마을회관 커뮤니티(DAO)</b>를 만들 거에요',
        en: 'Create a <b>town hall community(DAO)</b> where owners can discuss and vote on the direction and growth of SGJJ',
    },
    RoadmapItem6: {
        ko: '사료코인을 공개합니다. <b>사료코인</b>을 이용하여 커뮤니티 활동 및 새로운 NFT를 획득해 보세요',
        en: 'Reveal FEED Coin. Join community activities and get new NFT using FEED Coin',
    },
    RoadmapItem7: {
        ko: '시고르자브종 <b>굿즈판매</b>를 시작합니다. 오프라인에서도 시고르자브종을 자랑해 보세요',
        en: 'Begin SGJJ merchandise sales. Show off SGJJ offline',
    },
    RoadmapItem8: {
        ko: '<b>시고르자브종 이모티콘을 제작</b>할 거에요, <b>메신저</b>에서도 시고르자브종을 만나보세요',
        en: 'Create SGJJ <b>emoticons</b>, meet SGJJ on messenger',
    },
    RoadmapItem9: {
        ko: '시고르자브종 <b>메타버스 파티</b>를 준비할 거에요! 보호자들이 파티를 즐길 수 있어요',
        en: 'SGJJ <b>metaverse party</b>! Owners may attend the party',
    },
    RoadmapItem10: {
        ko: '모든 시고르자브종이 보호자들의 품으로 갔어요. 새로운 동물을 맞이할 준비를 해주세요<br />(<b>새로운 NFT 에어드랍</b>)',
        en: 'All SigorJabuJong have found an owner. Get ready to welcome a new animal<br />(<b>New NFT Airdrop</b>)',
    },
    FAQItemQ1: {
        ko: 'Q. 세상에서 가장 희귀한 품종인 시고르자브종이란 무엇인가요?',
        en: 'What is SigorJabuJong, the rarest breed in the world?',
    },
    FAQItemQ2: {
        ko: 'Q. 시고르자브종 NFT 소유자의 권리는 무엇인가요?',
        en: 'What are the SigorJabuJong NFT owners’ rights?',
    },
    FAQItemQ3: {
        ko: 'Q. 시고르자브종의 희귀도란 무엇인가요?',
        en: 'What is the rarity of SigorJabuJong?',
    },
    FAQItemQ4: {
        ko: 'Q. NFT는 어떻게 분배되나요?',
        en: 'How are NFTs distributed?',
    },
    FAQItemQ5: {
        ko: 'Q. 시고르자브종 홀더의 혜택은 무엇인가요?',
        en: 'What benefits do SigorJabuJong owners have?',
    },
    FAQItemQ6: {
        ko: 'Q. FEED Coin이란 무엇인가요?',
        en: 'What is a FEED Coin?',
    },
    FAQItemA1: {
        ko: '시고르자브종은 시골에서 태어난 잡종견을 고급스럽게 표현한 것입니다. 전설에 따르면 시골에서 우연히 시골잡종을 본 날에는 집안에 복이 굴러들어온다고 합니다.',
        en: 'SigorJabuJong is a luxurious interpretation of a mixed breed dog native to South Korea’s countryside towns. Legends say that on the day you cross paths with a countryside mixed breed dog by chance in the countryside, you will be blessed with fortune.',
    },
    FAQItemA2: {
        ko: '시고르자브종 분양증서를 가지고 있다면, 불법적이고 비윤리적인 활동을 제외한 모든 영리 및 비영리 활동의 권한과 책임을 가지게 됩니다. 시고르자브종 분양증서(NFT)를 가지고 있는 보호자(NFT소유자)는 시고르자브종 팀과 같이 시고르자브종의 지적재산권을 사용할 권리를 가지고 있습니다. 단, 보호자 분들의 지적재산권의 사용 범위는 분양증서가 나타내는 특정한 시고르자브종에 한정이므로 다른 사람의 강아지는 함부로 사용할 수 없습니다. 타인의 고귀한 시고르자브종을 도용하지 말아 주세요.',
        en: 'Being in possession of a SigorJabuJong certificate of sale gives you the authority and responsibility for all for-profit and non-profit activities, excluding all illegal and unethical activities. Guardians (NFT owners) who have a SigorJabuJong certificate of sale (NFT) have the right to use the SigorJabuJong intellectual property with the SGJJ team. However, since the scope of this right given to guardians is limited to the specific SigorJabuJong associated with the certificate of sale, other owners’ SigorJabuJongs cannot be used. Please do not use others’ SigorJabuJongs.',
    },
    FAQItemA3: {
        ko: '시고르자브종은 다양한 대한민국의 환경에 적응해가면서 모두 다르게 진화해 왔습니다. 사람이 없던 외딴 환경에 서식하던 시고르자브종은 다른 종들에 비해 더욱 희소하며 독특합니다. 이러한 진화의 원인은 시고르자브종의 신비 유전자 때문으로 추측되고 있으며 이는 다른 세계의 존재가 프로그래밍을 통해 시고르자브종에 이식했다는 가설이 유력합니다.',
        en: 'All SigorJabuJongs have evolved differently while adapting to the diverse environments of South Korea. Living in remote, uninhabited environments, the SigorJabuJongs are rarer and more unique than other species. A mysterious gene is speculated to be the cause of this evolution, and a popular hypothesis is that an extraterrestrial existence reprogrammed the DNA of SigorJabuJong species.',
    },
    FAQItemA4: {
        ko: '시고르자브종은 총 8000마리가 존재하며 그중 7200마리가 분양 희망자들에게, 500마리는 홍보에, 나머지 300마리는 시고르자브종팀에 위탁될 것입니다. 이후에 추가되는 시고르자브종은 없을 것이며 블록체인상에 오로지 8000마리의 특별한 시고르자브종만 존재할 것입니다.',
        en: 'There are a total of 8000 SigorJabuJongs, of which 7200 will be entrusted to those who wish to adopt, 500 to promotion, and the remaining 300 to the SigorJabuJong team. There will be only 8000 unique Sigor Jobu Jongs on the blockchain: additional Sigor Jobu Jongs will not be added.',
    },
    FAQItemA5: {
        ko: '첫째, 시고르자브종 보호자님들은 팀에서 지원하는 FEED토큰을 자동으로 지급 받게 됩니다. 이 토큰을 바탕으로 커뮤니티 정책에 참여할 수 있습니다. 둘째, 소유한 시고르자브종에 대한 모든 권한을 가지게 됩니다. 2차 저작물이나 혹은 영리목적으로도 활용 가능합니다.',
        en: 'First, the guardians of the SigorJabuJongs will automatically receive FEED tokens as a subsidy from the SGJJ team; they provide voting rights for the guardians to exercise within the community. Second, excluding all illegal and unethical activities, you will have full control over the SigorJabuJongs you own.',
    },
    FAQItemA6: {
        ko: '클레이튼에서 발행되는 토큰으로써 시고르자브종을 소유한 모두에게 주는 커뮤니티 지원금입니다. FEED 코인은 곧 추가될 커뮤니티 내에서 사용 가능하며, 나중에는 다양한 동물들을 도와주는 용도로 사용할 수 있습니다.',
        en: 'As a token issued by Klaytn, it is a subsidy given to everyone who owns a SigorJabuJong. FEED coins can be used within the community that will be added soon and later can be used to help diverse animals.',
    },
};
export function getTextMap(locale) {
    var res = {};
    Object.keys(TextRawMap).forEach(function (key) {
        res[key] = TextRawMap[key][locale];
    });
    return res;
}
