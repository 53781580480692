var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from 'react';
import styled from 'styled-components';
import Cloud1ImgPath from 'resource/etc/cloud1.png';
import Cloud2ImgPath from 'resource/etc/cloud2.png';
import Cloud3ImgPath from 'resource/etc/cloud3.png';
;
var Cloud = function (props) {
    return (React.createElement(CloudContainer, null,
        React.createElement(CloudSprite1, { src: Cloud1ImgPath }),
        React.createElement(CloudSprite2, { src: Cloud2ImgPath }),
        React.createElement(CloudSprite3, { src: Cloud3ImgPath })));
};
var CloudContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  @keyframes scollHorizontal {\n    from {\n      transform: translateX(", "px);\n    }\n    to {\n      transform: translateX(-100%);\n    }\n  }\n"], ["\n  @keyframes scollHorizontal {\n    from {\n      transform: translateX(", "px);\n    }\n    to {\n      transform: translateX(-100%);\n    }\n  }\n"])), window.outerWidth);
var cloudStyle = "\n  position: fixed;\n  left: 0;\n  z-index: 4;\n  opacity: 0.2;\n";
var CloudSprite1 = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n  top: 0;\n  animation: scollHorizontal 50s infinite linear;\n"], ["\n  ", "\n  top: 0;\n  animation: scollHorizontal 50s infinite linear;\n"])), cloudStyle);
var CloudSprite2 = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n  top: 40%;\n  animation: scollHorizontal 15s infinite linear;\n"], ["\n  ", "\n  top: 40%;\n  animation: scollHorizontal 15s infinite linear;\n"])), cloudStyle);
var CloudSprite3 = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n  top: 70%;\n  animation: scollHorizontal 40s infinite linear;\n"], ["\n  ", "\n  top: 70%;\n  animation: scollHorizontal 40s infinite linear;\n"])), cloudStyle);
export default Cloud;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
