import * as React from 'react';
import 'src/pages/App/Footer/Footer.scss';
var Footer = function () {
    return (React.createElement("div", { id: 'footer' },
        React.createElement("div", null, "Copyright \u00A9 2022 CountryMixDog All Rights Reserved."),
        React.createElement("div", { className: 'links' },
            React.createElement("a", { href: 'https://twitter.com/SGJJ_NFT', target: "_blank" }, "twitter"),
            ' ',
            "|",
            ' ',
            React.createElement("a", { href: 'https://discord.com/invite/3GpErFkCCc', target: "_blank" }, "discord"))));
};
export default Footer;
