var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from 'react';
import { useState, useEffect } from 'react';
import BgImgPath from 'resource/home/background/background.png';
import WallImgPath from 'resource/home/background/wall.png';
import HouseImgPath from 'resource/home/background/house.png';
import PuppyImgPath from 'resource/home/background/puppies.png';
import Star1ImgPath from 'resource/home/background/star1.png';
import Star2ImgPath from 'resource/home/background/star2.png';
import LogoWhite from 'resource/logo_white.png';
import styled from 'styled-components';
import { GoTriangleDown } from 'react-icons/go';
import { TextMapContext } from 'src/context';
import { styleMaxWidth, styleMinWidth } from 'src/common/styleUtil';
var debounce = false;
var MainContent = function () {
    var _a = useState(0), scroll = _a[0], setScroll = _a[1];
    var _b = useState(false), isPaused = _b[0], setIsPaused = _b[1];
    function onScroll() {
        if (!debounce && !isPaused) {
            debounce = true;
            setTimeout(function () {
                debounce = false;
                setScroll(window.scrollY);
            }, 10);
        }
    }
    useEffect(function () {
        window.addEventListener('scroll', onScroll);
        return function () {
            clearTimeout();
            setIsPaused(true);
            window.removeEventListener('scroll', onScroll);
        };
    }, []);
    return (React.createElement(TextMapContext.Consumer, null, function (_a) {
        var textMap = _a[0], locale = _a[1];
        return React.createElement(MainContentWrapper, null,
            React.createElement(MainContentContainer, null,
                React.createElement(Sprite, { src: BgImgPath }),
                React.createElement(Star1, { src: Star1ImgPath }),
                React.createElement(Star2, { src: Star2ImgPath }),
                React.createElement(Sprite, { src: HouseImgPath, style: { zIndex: 30, transform: "translateY(".concat(scroll * 0.4, "px)") } }),
                React.createElement(Sprite, { src: PuppyImgPath, style: { zIndex: 40, transform: "translateY(".concat(scroll * 0.2, "px)") } }),
                React.createElement(Sprite, { src: WallImgPath, style: { zIndex: 50 } }),
                React.createElement(MainContentTitleContainer, { style: { zIndex: 35, transform: "translateY(".concat(scroll * 0.8, "px)") } },
                    React.createElement(MainContentTitle, { locale: locale },
                        React.createElement("div", { style: { position: 'relative' } },
                            React.createElement(Logo, { src: LogoWhite }),
                            textMap.MainContentTitle[0]),
                        React.createElement("div", null, textMap.MainContentTitle[1])),
                    React.createElement(MainContentSubTitle, null,
                        React.createElement("div", null,
                            textMap.MainContentSubTitle[0],
                            " ",
                            textMap.MainContentSubTitle[1])))),
            (scroll < 200)
                && React.createElement(ScrollNotice, null,
                    React.createElement(GoTriangleDown, null),
                    React.createElement(GoTriangleDown, null),
                    React.createElement(GoTriangleDown, null)));
    }));
};
var MainContentTitleContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  width: 100vw;\n  text-align: center;\n  ", "\n  ", "\n"], ["\n  position: absolute;\n  width: 100vw;\n  text-align: center;\n  ", "\n  ", "\n"])), styleMinWidth(768, "\n    top: 120px;\n  "), styleMaxWidth(768, "\n    top: 20px;\n  "));
var MainContentTitle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: rgb(254 248 54);\n  letter-spacing: 2px;\n  div {\n    font-family: 'BMJUA';\n    display: inline-block;\n    font-size: ", ";\n  }\n  ", "\n  ", "\n\n"], ["\n  color: rgb(254 248 54);\n  letter-spacing: 2px;\n  div {\n    font-family: 'BMJUA';\n    display: inline-block;\n    font-size: ", ";\n  }\n  ", "\n  ", "\n\n"])), function (_a) {
    var locale = _a.locale;
    return locale === 'ko' ? '1em' : '0.6em';
}, styleMinWidth(768, "\n    white-space: nowrap;\n    font-size: 100px;\n  "), styleMaxWidth(768, "\n    white-space: wrap;\n    font-size: 56px;\n  "));
var MainContentSubTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  div {\n    text-align: center;\n    font-family: 'BMJUA';\n    margin-top: 20px;\n    line-height: 1.2;\n    color: #fff;\n    ", "\n    ", "\n  }\n"], ["\n  div {\n    text-align: center;\n    font-family: 'BMJUA';\n    margin-top: 20px;\n    line-height: 1.2;\n    color: #fff;\n    ", "\n    ", "\n  }\n"])), styleMaxWidth(768, "\n      font-size: 20px;\n      letter-spacing: 4px;\n    "), styleMinWidth(768, "\n      font-size: 30px;\n      letter-spacing: 11px;\n    "));
var MainContentWrapper = styled.section(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n  overflow: hidden;\n  width: 100%;\n  ", "\n  @keyframes twinkle1 { 50% { opacity: 0; } } \n  @keyframes twinkle2 { 50% { opacity: 0.7; } }\n"], ["\n  position: relative;\n  overflow: hidden;\n  width: 100%;\n  ", "\n  @keyframes twinkle1 { 50% { opacity: 0; } } \n  @keyframes twinkle2 { 50% { opacity: 0.7; } }\n"])), styleMaxWidth(768, "\n    height: 50vh;\n  "));
var MainContentContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  height: 100vh;\n"], ["\n  position: relative;\n  width: 100%;\n  height: 100vh;\n"])));
var spriteStyle = "\n  position: absolute;\n  width: 100%;\n  ".concat(styleMaxWidth(768, "\n    height: 50vh;\n  "), "\n  ").concat(styleMinWidth(768, "\n    height: 100vh;\n  "), "\n  object-fit: cover;\n  left: 0;\n  top: 0;\n  z-index: 30;\n");
var Sprite = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), spriteStyle);
var Logo = styled.img(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  position: absolute;\n  width: 90px;\n  left: 20px;\n  height: auto;\n  object-fit: contain;\n  opacity: 0.33;\n  z-index: 80;\n  transform: rotate(-15deg) translateX(-100%) translateY(-50%);\n  ", "\n"], ["\n  position: absolute;\n  width: 90px;\n  left: 20px;\n  height: auto;\n  object-fit: contain;\n  opacity: 0.33;\n  z-index: 80;\n  transform: rotate(-15deg) translateX(-100%) translateY(-50%);\n  ", "\n"])), styleMaxWidth(768, "\n    display: none;\n  "));
var Star1 = styled.img(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  ", "\n  opacity: 0.7;\n  animation: twinkle1 1s linear infinite;\n"], ["\n  ", "\n  opacity: 0.7;\n  animation: twinkle1 1s linear infinite;\n"])), spriteStyle);
var Star2 = styled.img(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  ", "\n  opacity: 0;\n  animation: twinkle2 1s linear infinite;\n"], ["\n  ", "\n  opacity: 0;\n  animation: twinkle2 1s linear infinite;\n"])), spriteStyle);
var ScrollNotice = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  position: fixed;\n  display: flex;\n  flex-direction: column;\n  ", "\n  ", "\n  z-index: 1000;\n  color: white;\n  font-size: 50px;\n  left: 50%;\n  transform: translateX(-50%);\n  svg {\n    margin-top: -25px;\n  }\n  @keyframes glow1 { 0% { opacity: 0 } 25% { opacity: 1 } 50% { opacity: 0 } 75% { opacity: 0 } 100% { opacity: 0 } }\n  @keyframes glow2 { 0% { opacity: 0 } 25% { opacity: 0 } 50% { opacity: 1 } 75% { opacity: 0 } 100% { opacity: 0 } }\n  @keyframes glow3 { 0% { opacity: 0 } 25% { opacity: 0 } 50% { opacity: 0 } 75% { opacity: 1 } 100% { opacity: 0 } }\n  svg:nth-of-type(1) { animation: glow1 1s infinite backwards; }\n  svg:nth-of-type(2) { animation: glow2 1s infinite backwards; }\n  svg:nth-of-type(3) { animation: glow3 1s infinite backwards; }\n"], ["\n  position: fixed;\n  display: flex;\n  flex-direction: column;\n  ", "\n  ", "\n  z-index: 1000;\n  color: white;\n  font-size: 50px;\n  left: 50%;\n  transform: translateX(-50%);\n  svg {\n    margin-top: -25px;\n  }\n  @keyframes glow1 { 0% { opacity: 0 } 25% { opacity: 1 } 50% { opacity: 0 } 75% { opacity: 0 } 100% { opacity: 0 } }\n  @keyframes glow2 { 0% { opacity: 0 } 25% { opacity: 0 } 50% { opacity: 1 } 75% { opacity: 0 } 100% { opacity: 0 } }\n  @keyframes glow3 { 0% { opacity: 0 } 25% { opacity: 0 } 50% { opacity: 0 } 75% { opacity: 1 } 100% { opacity: 0 } }\n  svg:nth-of-type(1) { animation: glow1 1s infinite backwards; }\n  svg:nth-of-type(2) { animation: glow2 1s infinite backwards; }\n  svg:nth-of-type(3) { animation: glow3 1s infinite backwards; }\n"])), styleMinWidth(768, "\n    bottom: 10vh;\n  "), styleMaxWidth(768, "\n    bottom: 20vh;\n  "));
export default MainContent;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
