var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from 'react';
import { useRef, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SwiperCore, { Autoplay } from 'swiper';
import styled from 'styled-components';
import { BsHandIndexThumbFill } from 'react-icons/bs';
import { styleMaxWidth, styleMinWidth, containerStyle, BackgroundPallet, mainText, titleText } from 'src/common/styleUtil';
import RaceImg1 from 'resource/race/1.png';
import RaceImg2 from 'resource/race/2.png';
import RaceImg3 from 'resource/race/3.png';
import RaceImg4 from 'resource/race/4.png';
import RaceImg5 from 'resource/race/5.png';
import RaceImg6 from 'resource/race/6.png';
import RaceImg7 from 'resource/race/7.png';
import RaceImg8 from 'resource/race/8.png';
import { useContext } from 'react';
import { TextMapContext } from 'src/context';
var RaceCard = /** @class */ (function () {
    function RaceCard(title, desc, color, imgPath) {
        this.title = title;
        this.desc = desc;
        this.color = color;
        this.imgPath = imgPath;
    }
    return RaceCard;
}());
var RaceItem = function (_a) {
    var raceCard = _a.raceCard;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    return (React.createElement(RaceItemWrapper, { onClick: function () { return setOpen(!open); } },
        React.createElement(RaceItemContainer, { style: { background: raceCard.color } },
            React.createElement(RaceItemImageWrapper, null,
                React.createElement("img", { src: raceCard.imgPath })),
            React.createElement(RaceItemTitle, { style: { color: raceCard.color === 'black' ? 'white' : 'auto' } }, raceCard.title)),
        open && React.createElement(RaceItemOverlay, null, raceCard.desc)));
};
var RaceSection = function () {
    var swiperContainerRef = useRef(null);
    var _a = useState(3), numPerView = _a[0], setNumPerView = _a[1];
    SwiperCore.use([Autoplay]);
    function onResize() {
        var boxSize = window.innerWidth > 767 ? 350 + 40 : 280 + 40;
        setNumPerView(swiperContainerRef.current.offsetWidth / boxSize);
    }
    useEffect(function () {
        onResize();
        window.addEventListener('resize', onResize);
        return function () { return window.removeEventListener('resize', onResize); };
    }, []);
    var _b = useContext(TextMapContext), textMap = _b[0], locale = _b[1];
    var raceCards = [];
    raceCards.push(new RaceCard(textMap['RaceTitle1'].toString(), textMap['RaceDescription1'].toString(), 'rgb(255 192 192)', RaceImg1));
    raceCards.push(new RaceCard(textMap['RaceTitle2'].toString(), textMap['RaceDescription2'].toString(), 'rgb(194 223 255)', RaceImg2));
    raceCards.push(new RaceCard(textMap['RaceTitle3'].toString(), textMap['RaceDescription3'].toString(), '#c5b593', RaceImg3));
    raceCards.push(new RaceCard(textMap['RaceTitle4'].toString(), textMap['RaceDescription4'].toString(), '#ccc', RaceImg4));
    raceCards.push(new RaceCard(textMap['RaceTitle5'].toString(), textMap['RaceDescription5'].toString(), 'rgb(220, 240, 246)', RaceImg5));
    raceCards.push(new RaceCard(textMap['RaceTitle6'].toString(), textMap['RaceDescription6'].toString(), 'rgb(225 216 197)', RaceImg6));
    raceCards.push(new RaceCard(textMap['RaceTitle7'].toString(), textMap['RaceDescription7'].toString(), 'rgb(197 197 225)', RaceImg7));
    raceCards.push(new RaceCard(textMap['RaceTitle8'].toString(), textMap['RaceDescription8'].toString(), 'black', RaceImg8));
    return (React.createElement(RaceWrapper, null,
        React.createElement(RaceContainer, null,
            React.createElement(RaceContent, null,
                React.createElement(RaceTitle, null,
                    React.createElement("div", null, "Gene")),
                React.createElement(RaceDesc, { dangerouslySetInnerHTML: {
                        __html: textMap['RaceContent'].toString()
                    } })),
            React.createElement(SwiperContainer, { ref: swiperContainerRef },
                React.createElement(Swiper, { spaceBetween: 40, slidesPerView: numPerView, autoplay: { delay: 3000 }, loop: true }, raceCards.map(function (raceCard) { return React.createElement(SwiperSlide, { key: 'rc' + raceCard.title },
                    React.createElement(RaceItem, { raceCard: raceCard })); })),
                React.createElement(RightArrow, null,
                    React.createElement(BsHandIndexThumbFill, null))))));
};
var RaceItemWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  text-align: center;\n  user-select: none;\n"], ["\n  width: 100%;\n  text-align: center;\n  user-select: none;\n"])));
var RaceItemContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  box-sizing: border-box;\n  display: inline-block;\n  padding: 20px;\n  border-top: 0;\n  border-bottom: 0;\n  border-radius: 5px;\n  user-select: none;\n  ", "\n  ", "\n"], ["\n  position: relative;\n  width: 100%;\n  box-sizing: border-box;\n  display: inline-block;\n  padding: 20px;\n  border-top: 0;\n  border-bottom: 0;\n  border-radius: 5px;\n  user-select: none;\n  ", "\n  ", "\n"])), styleMinWidth(768, "\n    min-width: 350px;\n  "), styleMaxWidth(768, "\n    min-width: 280px;\n  "));
var SwiperContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding-left: 20px;\n"], ["\n  padding-left: 20px;\n"])));
var RaceItemImageWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  img {\n    width: 100%;\n    border-radius: 5px;\n    margin-top: -30px;\n  }\n  &:hover {\n    opacity: 0.5;\n  }\n"], ["\n  width: 100%;\n  img {\n    width: 100%;\n    border-radius: 5px;\n    margin-top: -30px;\n  }\n  &:hover {\n    opacity: 0.5;\n  }\n"])));
var RaceItemTitle = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 36px;\n  font-weight: bold;\n  padding: 10px;\n  font-family: 'BMJUA';\n"], ["\n  font-size: 36px;\n  font-weight: bold;\n  padding: 10px;\n  font-family: 'BMJUA';\n"])));
var RaceItemOverlay = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  background: #000e;\n  display: flex;\n  align-items: center;\n  ", "\n"], ["\n  position: absolute;\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  background: #000e;\n  display: flex;\n  align-items: center;\n  ", "\n"])), mainText);
var RaceWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 200px 0 200px;\n  background: ", ";\n  position: relative;\n  z-index: 100;\n"], ["\n  padding: 200px 0 200px;\n  background: ", ";\n  position: relative;\n  z-index: 100;\n"])), BackgroundPallet.race);
var RaceContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), containerStyle);
var RaceContent = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin-bottom: 40px;\n  padding-left: 20px;\n  ", "\n"], ["\n  margin-bottom: 40px;\n  padding-left: 20px;\n  ", "\n"])), styleMaxWidth(768, "\n    padding-right: 20px;\n  "));
var RaceTitle = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  ", "\n  display: inline-block;\n  margin-bottom: 15px;\n"], ["\n  ", "\n  display: inline-block;\n  margin-bottom: 15px;\n"])), titleText);
var RaceDesc = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), mainText);
var RightArrow = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  color: white;\n  display: block;\n  text-align: center;\n  margin-top: 10px;\n  font-size: 20px;\n  cursor: pointer;\n  user-select: none;\n  padding: 0 10px;\n  float: center;\n  @keyframes rightArrow {\n    0% { transform: translateX(50px); opacity: 0 }\n    20% { opacity: 1; }\n    100% { transform: translateX(-50px); opacity: 0 }\n  }\n  svg {\n    animation: rightArrow 1s infinite;\n  }\n"], ["\n  color: white;\n  display: block;\n  text-align: center;\n  margin-top: 10px;\n  font-size: 20px;\n  cursor: pointer;\n  user-select: none;\n  padding: 0 10px;\n  float: center;\n  @keyframes rightArrow {\n    0% { transform: translateX(50px); opacity: 0 }\n    20% { opacity: 1; }\n    100% { transform: translateX(-50px); opacity: 0 }\n  }\n  svg {\n    animation: rightArrow 1s infinite;\n  }\n"])));
export default RaceSection;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
