var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { useState, useRef, useContext } from 'react';
import { TextMapContext, Web3Context } from 'src/context';
import BlackDog from 'resource/etc/blackpuppy.png';
import styled from 'styled-components';
import { toExpectedRestAmount } from 'src/common';
import { styleMaxWidth } from 'src/common/styleUtil';
var SearchRestFeed = function (_a) {
    var _b = useState(0), resultId = _b[0], setResultId = _b[1];
    var _c = useState(false), isExist = _c[0], setIsExist = _c[1];
    var confirmRef = useRef();
    var web3Service = useContext(Web3Context)[0];
    var _d = useState(null), infoElement = _d[0], setInfoElement = _d[1];
    var _e = useContext(TextMapContext), textMap = _e[0], locale = _e[1];
    function updateResult(tokenId) {
        return __awaiter(this, void 0, void 0, function () {
            var res, hashPower, recentlyClaimedAt, startedAt, limit, expectedRestAmount, claimable;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, web3Service.getFeedeeMap(tokenId)];
                    case 1:
                        res = _a.sent();
                        setResultId(tokenId);
                        if ((res === null || res === void 0 ? void 0 : res.startedAt) <= 0) {
                            setInfoElement(React.createElement("div", null, textMap['NotAppliedGrantNFT']));
                            setIsExist(false);
                            return [2 /*return*/];
                        }
                        setIsExist(true);
                        hashPower = res.hashPower, recentlyClaimedAt = res.recentlyClaimedAt, startedAt = res.startedAt, limit = res.limit;
                        expectedRestAmount = Math.round(toExpectedRestAmount(limit, startedAt, recentlyClaimedAt, hashPower) / Math.pow(10, 15)) / 1000;
                        return [4 /*yield*/, web3Service.getClaimableFeed(tokenId)];
                    case 2:
                        claimable = _a.sent();
                        setInfoElement(React.createElement("div", null,
                            React.createElement("h1", null, textMap['HashPower']),
                            React.createElement("h2", null,
                                Math.round(Number(hashPower) / Math.pow(10, 9)),
                                React.createElement("span", { style: { fontSize: 10, opacity: 0.5 } }, "K gwei/s")),
                            React.createElement("h1", null, textMap['GrantsLimit']),
                            React.createElement("h2", null,
                                expectedRestAmount,
                                React.createElement("span", { style: { fontSize: 10, opacity: 0.5 } }, " FEED")),
                            React.createElement("h1", null, textMap['Claimable']),
                            React.createElement("h2", null,
                                Math.floor(Number(claimable) / Math.pow(10, 15)) / 1000,
                                React.createElement("span", { style: { fontSize: 10, opacity: 0.5 } }, " FEED"))));
                        return [2 /*return*/];
                }
            });
        });
    }
    return (React.createElement(SearchRestFeedContainer, null,
        React.createElement(SearchInput, null,
            React.createElement("input", { ref: confirmRef, type: "number", defaultValue: resultId }),
            React.createElement("button", { onClick: function (e) {
                    var ele = confirmRef.current;
                    var _resultId = parseInt(ele === null || ele === void 0 ? void 0 : ele.value);
                    if (_resultId > 8000)
                        _resultId = 8000;
                    if (_resultId < 1)
                        _resultId = 1;
                    ele.value = _resultId;
                    confirmRef.current.value = _resultId;
                    updateResult(_resultId);
                } }, textMap['Search'])),
        React.createElement(SearchResult, null,
            isExist ? React.createElement("img", { src: "https://public.countrymixdog.io/sprite/".concat(resultId, ".png") })
                : React.createElement("img", { src: BlackDog, style: { background: 'gray' } }),
            React.createElement(ResultDashboard, null, infoElement))));
};
var ResultDashboard = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: white;\n  width: 200px;\n  height: 200px;\n  background: #aaa3;\n  padding: 10px;\n  h1 {\n    color: grey;\n    font-size: 15px;\n    margin-top: 10px;\n  }\n  h2 {\n    text-align: center;\n    margin: 4px 0;\n    font-size: 18px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: white;\n  width: 200px;\n  height: 200px;\n  background: #aaa3;\n  padding: 10px;\n  h1 {\n    color: grey;\n    font-size: 15px;\n    margin-top: 10px;\n  }\n  h2 {\n    text-align: center;\n    margin: 4px 0;\n    font-size: 18px;\n  }\n"])));
var SearchResult = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  ", "\n  max-width: 100%;\n  img {\n    width: 220px;\n    height: 220px;\n  }\n"], ["\n  display: flex;\n  ", "\n  max-width: 100%;\n  img {\n    width: 220px;\n    height: 220px;\n  }\n"])), styleMaxWidth(767, "\n    flex-direction: column;\n  "));
var SearchRestFeedContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n"], ["\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n"])));
var SearchInput = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin: 20px 0;\n  input {\n    font-size: 24px;\n    text-align: center;\n    padding: 6px 0;\n    width: 120px;\n    background: #363676;\n    color: white;\n    border: 0;\n    &::-webkit-inner-spin-button,&::-webkit-outer-spin-button {\n      -webkit-appearance: none; \n      margin: 0; \n    }\n  }\n  button {\n    color: white;\n    background: blue;\n    padding: 6px 0;\n    font-size: 24px;\n    border: 0;\n    width: 100px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  margin: 20px 0;\n  input {\n    font-size: 24px;\n    text-align: center;\n    padding: 6px 0;\n    width: 120px;\n    background: #363676;\n    color: white;\n    border: 0;\n    &::-webkit-inner-spin-button,&::-webkit-outer-spin-button {\n      -webkit-appearance: none; \n      margin: 0; \n    }\n  }\n  button {\n    color: white;\n    background: blue;\n    padding: 6px 0;\n    font-size: 24px;\n    border: 0;\n    width: 100px;\n  }\n"])));
export default SearchRestFeed;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
