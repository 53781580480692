var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from 'react';
import styled from 'styled-components';
import SlidesImg1Path from 'resource/intro/g1.gif';
import SlidesImg2Path from 'resource/intro/g2.gif';
import SlidesImg3Path from 'resource/intro/g3.gif';
import SlidesImg4Path from 'resource/intro/g4.gif';
import { BsFillCaretDownFill } from 'react-icons/bs';
import { styleMaxWidth, styleMinWidth } from 'src/common/styleUtil';
import { TextMapContext } from 'src/context';
import { useNavigate } from 'react-router';
import SimpleButton from 'src/component/SimpleButton';
var ButtonContainer = function () {
    var navigate = useNavigate();
    return (React.createElement(TextMapContext.Consumer, null, function (_a) {
        var textMap = _a[0], locale = _a[1];
        return React.createElement(ButtonContainerWrapper, null,
            React.createElement(PreviewContainer, null,
                React.createElement("img", { src: SlidesImg1Path, alt: 'slides' }),
                React.createElement("img", { src: SlidesImg2Path, alt: 'slides' }),
                React.createElement("img", { src: SlidesImg3Path, alt: 'slides' }),
                React.createElement("img", { src: SlidesImg4Path, alt: 'slides' })),
            React.createElement(KlaytnGuide, null,
                React.createElement("div", { dangerouslySetInnerHTML: {
                        __html: textMap['WalletGuideLinkDesc'].toString(),
                    } }),
                ' ',
                React.createElement("div", null,
                    React.createElement("a", { href: textMap['WalletGuidePdfLink'].toString(), target: '_blank' }, textMap['WalletGuideLinkText']))),
            React.createElement(ButtonContainerContainer, null,
                React.createElement(SimpleButton, { onClick: function () {
                        navigate("/".concat(locale, "/mint"));
                        window.scrollTo(0, 0);
                    }, type: 'blue', text: textMap['MainContentMintBtn'].toString() }),
                React.createElement(SimpleButton, { onClick: function () { return window.open('https://opensea.io/collection/sigorjabujong'); }, type: 'darkgreen', text: "Opensea" })),
            React.createElement(ButtonContainerMessage, null,
                textMap['ButtonContainerScollNote'],
                React.createElement("br", null),
                React.createElement(BsFillCaretDownFill, { style: { marginTop: 10 } })));
    }));
};
var ButtonContainerWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  ", "\n  text-align: center;\n"], ["\n  ", "\n  ", "\n  text-align: center;\n"])), styleMinWidth(767, "\n    margin-bottom: 200px;\n  "), styleMaxWidth(767, "\n    margin-bottom: 100px;\n  "));
var ButtonContainerMessage = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 16px;\n  color: white;\n  margin-top: 50px;\n  @keyframes arrowDown {\n    from { transform: translateY(0) }\n    to { transform: translateY(5px) }\n  }\n  svg {\n    animation: arrowDown 333ms alternate infinite;\n  }\n"], ["\n  font-size: 16px;\n  color: white;\n  margin-top: 50px;\n  @keyframes arrowDown {\n    from { transform: translateY(0) }\n    to { transform: translateY(5px) }\n  }\n  svg {\n    animation: arrowDown 333ms alternate infinite;\n  }\n"])));
var ButtonContainerContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  color: white;\n  font-size: 40px;\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  color: white;\n  font-size: 40px;\n"])));
var PreviewContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  flex-wrap: wrap;\n  margin: auto;\n  ", "\n  ", "\n  img {\n    ", "\n    ", "\n    border-radius: 10px;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  flex-wrap: wrap;\n  margin: auto;\n  ", "\n  ", "\n  img {\n    ", "\n    ", "\n    border-radius: 10px;\n  }\n"])), styleMinWidth(767, "\n    padding: 0 0 40px;\n  "), styleMaxWidth(767, "\n    padding: 0 0 40px;\n  "), styleMinWidth(767, "\n      width: 15%;\n      margin: 1%;\n    "), styleMaxWidth(767, "\n      width: 40%;\n      margin: 2%;\n    "));
var KlaytnGuide = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: white;\n  font-size: 16px;\n  margin: 20px 0 50px;\n  line-height: 1.5;\n  div {\n    display: inline-block;\n  }\n  b {\n    color: #fffdc1;\n    font-weight: bold;\n  }\n  a {\n    color: skyblue;\n  }\n"], ["\n  color: white;\n  font-size: 16px;\n  margin: 20px 0 50px;\n  line-height: 1.5;\n  div {\n    display: inline-block;\n  }\n  b {\n    color: #fffdc1;\n    font-weight: bold;\n  }\n  a {\n    color: skyblue;\n  }\n"])));
export default ButtonContainer;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
