export function toHex(demical) {
    return "0x".concat(demical.toString(16));
}
export function toExpectedRestAmount(limit, startedAt, recentlyClaimedAt, hashPower) {
    if (typeof limit === 'bigint')
        limit = Number(limit);
    if (typeof recentlyClaimedAt === 'bigint')
        recentlyClaimedAt = Number(recentlyClaimedAt);
    if (typeof startedAt === 'bigint')
        startedAt = Number(startedAt);
    if (typeof hashPower === 'bigint')
        hashPower = Number(hashPower);
    return Math.max(limit - (recentlyClaimedAt - startedAt) * hashPower, 0);
}
