var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { useContext, useState } from 'react';
import { containerStyle, styleMaxWidth, styleMinWidth, titleText } from 'src/common/styleUtil';
import styled from 'styled-components';
import MintInnerContainer from './MintInnerContainer';
import { AddressContext, FeedBalanceContext, PopupContext, TextMapContext, Web3Context } from 'src/context';
import OwnList from './OwnList';
import MintFailPopup from 'src/component/MintFailPopup';
var IMG_BASE_URL = process.env.IMG_BASE_URL;
function PreviewImgs(props) {
    var list = props.list;
    return (React.createElement("span", { className: props.className }, list));
}
var Mint = function () {
    var list = [];
    var _a = useState(1), step = _a[0], setStep = _a[1];
    var web3Service = useContext(Web3Context)[0];
    var _b = useContext(AddressContext), address = _b[0], setAddress = _b[1];
    var setPopup = useContext(PopupContext);
    var _c = useContext(TextMapContext), textMap = _c[0], locale = _c[1];
    var _d = useContext(FeedBalanceContext), feedBalacne = _d[0], setFeedBalance = _d[1];
    for (var i = 0; i < 20; i++) {
        var tokenId = Math.floor(Math.random() * 8000) + 1;
        list.push(React.createElement(PreviewImg, { key: "preview_".concat(i), src: "".concat(IMG_BASE_URL).concat(tokenId, ".png"), alt: "nft preview" }));
    }
    return (React.createElement(MintWrapper, null,
        React.createElement(MintContainer, null,
            React.createElement(MintTitle, null,
                React.createElement("div", null, "Mint")),
            React.createElement(MintInnerContainer, { step: step, setStep: setStep, publicMintClick: function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var isOpen, e_1;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, web3Service.getPublicSaleConfig()];
                                case 1:
                                    isOpen = (_a.sent()).isOpen;
                                    if (!!isOpen) return [3 /*break*/, 2];
                                    alert(textMap['PublicMintingNotStarted']);
                                    return [3 /*break*/, 5];
                                case 2:
                                    _a.trys.push([2, 4, , 5]);
                                    return [4 /*yield*/, web3Service.publicSaleMint(address)];
                                case 3:
                                    _a.sent();
                                    return [3 /*break*/, 5];
                                case 4:
                                    e_1 = _a.sent();
                                    setPopup(React.createElement(MintFailPopup, null));
                                    return [3 /*break*/, 5];
                                case 5: return [2 /*return*/];
                            }
                        });
                    });
                }, preMintClick: function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var isOpen, e_2;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, web3Service.getPreSaleConfig()];
                                case 1:
                                    isOpen = (_a.sent()).isOpen;
                                    if (!!isOpen) return [3 /*break*/, 2];
                                    alert(textMap['WhiteListMintingNotStarted']);
                                    return [3 /*break*/, 5];
                                case 2:
                                    _a.trys.push([2, 4, , 5]);
                                    return [4 /*yield*/, web3Service.preSaleMint(address)];
                                case 3:
                                    _a.sent();
                                    return [3 /*break*/, 5];
                                case 4:
                                    e_2 = _a.sent();
                                    setPopup(React.createElement(MintFailPopup, { whiteListDesc: true }));
                                    return [3 /*break*/, 5];
                                case 5: return [2 /*return*/];
                            }
                        });
                    });
                }, payByTokenMintClick: function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var isOpen, _a, e_3;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0: return [4 /*yield*/, web3Service.getPayByTokenSaleConfig()];
                                case 1:
                                    isOpen = (_b.sent()).isOpen;
                                    if (!!isOpen) return [3 /*break*/, 2];
                                    alert(textMap['PayByTokenNotStarted']);
                                    return [3 /*break*/, 6];
                                case 2:
                                    _b.trys.push([2, 5, , 6]);
                                    return [4 /*yield*/, web3Service.payByTokenSaleMint(address)];
                                case 3:
                                    _b.sent();
                                    _a = setFeedBalance;
                                    return [4 /*yield*/, web3Service.getFeedBalance(address)];
                                case 4:
                                    _a.apply(void 0, [_b.sent()]);
                                    return [3 /*break*/, 6];
                                case 5:
                                    e_3 = _b.sent();
                                    setPopup(React.createElement(MintFailPopup, { payByToken: true }));
                                    return [3 /*break*/, 6];
                                case 6: return [2 /*return*/];
                            }
                        });
                    });
                } }),
            React.createElement(Divider, null),
            React.createElement(OwnList, null)),
        React.createElement(PreviewContainer, null,
            React.createElement(PreviewImgs, { className: "i1", list: list }),
            React.createElement(PreviewImgs, { className: "i2", list: list }))));
};
var Divider = styled.hr(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: 0;\n  height: 1px;\n  width: 100%;\n  background: #29297b;\n  margin: 50px 0;\n\n"], ["\n  border: 0;\n  height: 1px;\n  width: 100%;\n  background: #29297b;\n  margin: 50px 0;\n\n"])));
var PreviewContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  white-space: nowrap;\n  position: relative;\n  height: 150px;\n  width: 100vw;\n  overflow: hidden;\n  will-change: transform;\n  .i1 {\n    position: absolute;\n    left: 0px;\n    animation: scollX 20s linear infinite;\n  }\n  .i2 {\n    position: absolute;\n    left: 3000px;\n    animation: scollX 20s linear infinite;\n  }\n"], ["\n  white-space: nowrap;\n  position: relative;\n  height: 150px;\n  width: 100vw;\n  overflow: hidden;\n  will-change: transform;\n  .i1 {\n    position: absolute;\n    left: 0px;\n    animation: scollX 20s linear infinite;\n  }\n  .i2 {\n    position: absolute;\n    left: 3000px;\n    animation: scollX 20s linear infinite;\n  }\n"])));
var PreviewImg = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 150px;\n  height: 150px;\n"], ["\n  width: 150px;\n  height: 150px;\n"])));
var MintWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n  ", "\n  background: rgb(5, 5, 60);\n"], ["\n  ", "\n  ", "\n  background: rgb(5, 5, 60);\n"])), styleMinWidth(767, "\n    padding-top: 70px;\n  "), styleMaxWidth(767, "\n    padding-top: 50px;\n  "));
var MintContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 80px 0 140px 0;\n  ", "\n"], ["\n  padding: 80px 0 140px 0;\n  ", "\n"])), containerStyle);
var MintTitle = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", "\n  padding: 0 20px;\n"], ["\n  ", "\n  padding: 0 20px;\n"])), titleText);
export default Mint;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
