var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { useContext } from 'react';
import { AddressContext, NetworkIdContext, TextMapContext, Web3Context } from 'src/context';
import styled from 'styled-components';
import kaikasIconPath from 'resource/etc/kaikas-icon.png';
import metamaskIconPath from 'resource/etc/metamask-icon.png';
var WalletSelectPopup = function (props) {
    var closeFunc = props.closeFunc;
    var web3Service = useContext(Web3Context)[0];
    var textMap = useContext(TextMapContext)[0];
    var _a = useContext(AddressContext), address = _a[0], setAddress = _a[1];
    var _b = useContext(NetworkIdContext), networkId = _b[0], setNetworkId = _b[1];
    return (React.createElement(WalletSelectPopupWrapper, null,
        React.createElement("h1", null, textMap['CommonConnectWallet']),
        React.createElement(WalletButtonContainer, null,
            React.createElement("button", { onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var _a, e_1;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                _a = setAddress;
                                return [4 /*yield*/, web3Service.connectKaikas(setAddress)];
                            case 1:
                                _a.apply(void 0, [_b.sent()]);
                                setNetworkId(web3Service.getChainId());
                                closeFunc();
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _b.sent();
                                alert('please check Kaikas installed');
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); } },
                React.createElement("img", { src: kaikasIconPath, alt: "kaikas icon" }),
                React.createElement("div", null, textMap['CommonKaikas']))),
        React.createElement(WalletButtonContainer, null,
            React.createElement("button", { onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var _a, e_2;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                _a = setAddress;
                                return [4 /*yield*/, web3Service.connectMetamask(setAddress)];
                            case 1:
                                _a.apply(void 0, [_b.sent()]);
                                setNetworkId(web3Service.getChainId());
                                closeFunc();
                                return [3 /*break*/, 3];
                            case 2:
                                e_2 = _b.sent();
                                alert('please check Metamask installed');
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); } },
                React.createElement("img", { src: metamaskIconPath, alt: "metamask icon" }),
                React.createElement("div", null, textMap['CommonMetamask'])))));
};
var WalletSelectPopupWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 20px;\n  background: white;\n  border-radius: 5px;\n  h1 {\n    font-weight: bold;\n    font-size: 26px;\n    margin-bottom: 15px;\n  }\n  min-width: 280px;\n"], ["\n  padding: 20px;\n  background: white;\n  border-radius: 5px;\n  h1 {\n    font-weight: bold;\n    font-size: 26px;\n    margin-bottom: 15px;\n  }\n  min-width: 280px;\n"])));
var WalletButtonContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  button {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    border: 1px solid black;\n    margin: 5px 0;\n    font-size: 23px;\n    background: none;\n    padding: 10px 20px;\n    border-radius: 10px;\n    img {\n      width: 1.5em;\n      height: 1.5em;\n      margin-right: 20px;\n    }\n    cursor: pointer;\n    user-select: none;\n    &:hover {\n      background: #fffee9;\n    }\n    transition: background 100ms ease;\n  }\n"], ["\n  position: relative;\n  button {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    border: 1px solid black;\n    margin: 5px 0;\n    font-size: 23px;\n    background: none;\n    padding: 10px 20px;\n    border-radius: 10px;\n    img {\n      width: 1.5em;\n      height: 1.5em;\n      margin-right: 20px;\n    }\n    cursor: pointer;\n    user-select: none;\n    &:hover {\n      background: #fffee9;\n    }\n    transition: background 100ms ease;\n  }\n"])));
export default WalletSelectPopup;
var templateObject_1, templateObject_2;
