var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SiDiscord, SiTwitter } from 'react-icons/si';
import { GiHamburgerMenu } from 'react-icons/gi';
import { TextMapContext } from 'src/context';
import { styleMinWidth, styleMaxWidth } from 'src/common/styleUtil';
import LanguageSelect from './LanguageSelect';
var debounce = false;
var NavBar = function (_a) {
    var setHamburgerActive = _a.setHamburgerActive;
    var _b = useState(0), scroll = _b[0], setScroll = _b[1];
    function onScroll() {
        if (!debounce) {
            debounce = true;
            setTimeout(function () {
                debounce = false;
                setScroll(window.scrollY);
            }, 10);
        }
    }
    useEffect(function () {
        window.addEventListener('scroll', onScroll);
        return function () {
            clearTimeout();
            window.removeEventListener('scroll', onScroll);
        };
    }, []);
    return (React.createElement(TextMapContext.Consumer, null, function (_a) {
        var textMap = _a[0], locale = _a[1];
        return React.createElement(Navigation, { isDark: scroll > window.outerHeight / 1.8 },
            React.createElement(NavigationBackground, null),
            React.createElement(NavContainer, null,
                React.createElement(NavIconLinkItem, { onClick: function () { return setHamburgerActive(true); } },
                    React.createElement(GiHamburgerMenu, { color: '#fff' })),
                React.createElement("div", null),
                React.createElement("div", null,
                    React.createElement(LanguageSelect, { locale: locale }),
                    React.createElement("a", { href: "https://twitter.com/SGJJ_NFT", target: "_blank" },
                        React.createElement(NavIconLinkItem, null,
                            React.createElement(SiTwitter, { color: '#ddf' }),
                            React.createElement("span", { style: { fontSize: 14 } }, "Twitter"),
                            ' ')),
                    React.createElement("a", { href: "https://discord.com/invite/3GpErFkCCc", target: "_blank" },
                        React.createElement(NavIconLinkItem, null,
                            React.createElement(SiDiscord, { color: '#ddf' }),
                            React.createElement("span", { style: { fontSize: 14 } }, "Discord"),
                            ' ')))));
    }));
};
var Navigation = styled.nav(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  ", "\n  top: 0;\n  left: 0;\n  right: 0;\n  z-index: 500;\n  transition: background 400ms, box-shadow 500ms;\n  ", "\n  ", "\n"], ["\n  position: fixed;\n  ", "\n  top: 0;\n  left: 0;\n  right: 0;\n  z-index: 500;\n  transition: background 400ms, box-shadow 500ms;\n  ", "\n  ", "\n"])), function (_a) {
    var isDark = _a.isDark;
    if (isDark) {
        return "\n        background: #05053b;\n        box-shadow: 0 3px 1px 0 #000c;\n      ";
    }
    return "background: #0d2185;";
}, styleMinWidth(767, "\n    height: 70px;\n  "), styleMaxWidth(767, "\n    height: 50px;\n  "));
var NavigationBackground = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n"], ["\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n"])));
var NavContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 1280px;\n  max-width: 100%;\n  height: 100%;\n  margin: auto;\n  a {\n    text-decoration: none;\n  }\n"], ["\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 1280px;\n  max-width: 100%;\n  height: 100%;\n  margin: auto;\n  a {\n    text-decoration: none;\n  }\n"])));
var NavIconLinkItem = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: inline-flex;\n  align-items: baseline;\n  gap: 3px;\n  font-size: 24px;\n  color: #ddd;\n  font-family: 'BMJUA';\n  text-decoration: none;\n  padding: 10px;\n  user-select: none;\n  cursor: pointer;\n  &:hover {\n    color: #fff;\n    background: #fff3;\n    border-radius: 5px;\n  }\n"], ["\n  display: inline-flex;\n  align-items: baseline;\n  gap: 3px;\n  font-size: 24px;\n  color: #ddd;\n  font-family: 'BMJUA';\n  text-decoration: none;\n  padding: 10px;\n  user-select: none;\n  cursor: pointer;\n  &:hover {\n    color: #fff;\n    background: #fff3;\n    border-radius: 5px;\n  }\n"])));
export default NavBar;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
