var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from 'react';
import styled from 'styled-components';
import MintBtnImgPath from 'resource/button/mint.png';
import OpenseaBtnImgPath from 'resource/button/opensea.png';
import whitePath from 'resource/button/white.png';
import skyPath from 'resource/button/sky.png';
import orangePath from 'resource/button/orange.png';
import grayPath from 'resource/button/gray.png';
var TypeToPath = {
    blue: MintBtnImgPath,
    darkgreen: OpenseaBtnImgPath,
    white: whitePath,
    sky: skyPath,
    orange: orangePath,
    gray: grayPath,
};
var SimpleButton = function (props) {
    var type = props.type, text = props.text, subText = props.subText, onClick = props.onClick, width = props.width, textColor = props.textColor, subTextColor = props.subTextColor, noMouseOver = props.noMouseOver;
    return (React.createElement(MintButton, { onClick: function () { return onClick(); }, width: width, textColor: textColor, subTextColor: subTextColor, noMouseOver: noMouseOver },
        React.createElement("img", { src: TypeToPath[type], alt: 'button skin' }),
        React.createElement("h1", null, text),
        subText && React.createElement("h2", null, subText)));
};
var MintButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  position: relative;\n  min-width: 220px;\n  height: 100px;\n  background: none;\n  border: 0;\n  user-select: none;\n  margin: 0 20px;\n  padding: 0 20px 4px 16px;\n  cursor: pointer;\n  ", "\n\n", "\n  \n  img {\n    position: absolute;\n    left:0; top: 0;\n    width: 100%;\n    height: 100%;\n    z-index: -1;\n  }\n  h1 {\n    ", "\n    font-size: 30px;\n    font-family: 'BMJUA';\n    margin-top: -4px;\n    letter-spacing: 1px;\n  }\n  h2 {\n    ", "\n    font-size: 20px;\n    margin-top: 5px;\n    font-family: 'BMJUA';\n    letter-spacing: 1px;\n  }\n"], ["\n  ", "\n  position: relative;\n  min-width: 220px;\n  height: 100px;\n  background: none;\n  border: 0;\n  user-select: none;\n  margin: 0 20px;\n  padding: 0 20px 4px 16px;\n  cursor: pointer;\n  ", "\n\n", "\n  \n  img {\n    position: absolute;\n    left:0; top: 0;\n    width: 100%;\n    height: 100%;\n    z-index: -1;\n  }\n  h1 {\n    ", "\n    font-size: 30px;\n    font-family: 'BMJUA';\n    margin-top: -4px;\n    letter-spacing: 1px;\n  }\n  h2 {\n    ", "\n    font-size: 20px;\n    margin-top: 5px;\n    font-family: 'BMJUA';\n    letter-spacing: 1px;\n  }\n"])), function (_a) {
    var width = _a.width;
    return width && "width: ".concat(width, "px;");
}, function (_a) {
    var noMouseOver = _a.noMouseOver;
    return !noMouseOver && "\n    &:hover {\n      opacity: 0.5;\n    }\n  ";
}, function (_a) {
    var noMouseOver = _a.noMouseOver;
    return noMouseOver && "\n    opacity: 0.3;\n  ";
}, function (_a) {
    var textColor = _a.textColor;
    return textColor ? "color: ".concat(textColor, ";") : 'color: white;';
}, function (_a) {
    var subTextColor = _a.subTextColor;
    return subTextColor ? "color: ".concat(subTextColor, ";") : 'color: #ccc;';
});
export default SimpleButton;
var templateObject_1;
