var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { containerStyle, BackgroundPallet } from 'src/common/styleUtil';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { useContext } from 'react';
import { TextMapContext } from 'src/context';
var FaqItem = function (_a) {
    var q = _a.q, a = _a.a, defaultOpen = _a.defaultOpen;
    var _b = useState(defaultOpen), open = _b[0], setOpen = _b[1];
    return (React.createElement(FaqItemContainer, { onClick: function () { return setOpen(!open); } },
        React.createElement(FaqItemQuestion, null,
            React.createElement("span", null, q),
            open ? React.createElement(MdExpandLess, null) : React.createElement(MdExpandMore, null)),
        open && React.createElement(FaqItemAnswer, null, a)));
};
var FaqSection = function () {
    var _a = useContext(TextMapContext), textMap = _a[0], locale = _a[1];
    var FaqData = [];
    for (var i = 0; i < 6; i++) {
        FaqData.push({
            q: textMap["FAQItemQ".concat(i + 1)].toString(),
            a: textMap["FAQItemA".concat(i + 1)].toString(),
        });
    }
    FaqData[0].defaultOpen = true;
    return (React.createElement(FaqSectionWrapper, null,
        React.createElement(FaqSectionContainer, null,
            React.createElement(FaqSectionTitle, null, "FAQ"),
            React.createElement(FaqItemWrapper, null, FaqData.map(function (data) {
                return React.createElement(FaqItem, { key: data.q, q: data.q, a: data.a, defaultOpen: data.defaultOpen });
            }))),
        React.createElement(FaqBottomWrapper, null)));
};
var FaqSectionWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0 0 200px 0;\n  background: ", ";\n  position: relative;\n"], ["\n  padding: 0 0 200px 0;\n  background: ", ";\n  position: relative;\n"])), BackgroundPallet.faq);
var FaqSectionContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), containerStyle);
var FaqSectionTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: 'Berlin Sans';\n  color: #FEF836;\n  font-size: 46px;\n  padding: 0 20px;\n  text-shadow: 1px 1px 10px #000;\n"], ["\n  font-family: 'Berlin Sans';\n  color: #FEF836;\n  font-size: 46px;\n  padding: 0 20px;\n  text-shadow: 1px 1px 10px #000;\n"])));
var FaqItemWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 20px;\n"], ["\n  padding: 20px;\n"])));
var FaqItemContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 15px 0;\n  line-height: 1.4;\n  cursor: pointer;\n"], ["\n  padding: 15px 0;\n  line-height: 1.4;\n  cursor: pointer;\n"])));
var FaqItemQuestion = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  user-select: none;\n  color: white;\n  font-size: 20px;\n  span {\n    color: white;\n  }\n  border-bottom: 1px solid #ddd;\n"], ["\n  display: flex;\n  align-items: center;\n  user-select: none;\n  color: white;\n  font-size: 20px;\n  span {\n    color: white;\n  }\n  border-bottom: 1px solid #ddd;\n"])));
var FaqItemAnswer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: #000;\n  font-size: 18px;\n  padding: 20px;\n  border-radius: 25px;\n  margin-top: 10px;\n  background: #ffffffdb;\n"], ["\n  color: #000;\n  font-size: 18px;\n  padding: 20px;\n  border-radius: 25px;\n  margin-top: 10px;\n  background: #ffffffdb;\n"])));
var FaqBottomWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: absolute;\n  left: 0;\n  bottom: 0;\n  width: 100%;\n  height: 20%;\n  background: ", ";\n  z-index: 7;\n"], ["\n  position: absolute;\n  left: 0;\n  bottom: 0;\n  width: 100%;\n  height: 20%;\n  background: ", ";\n  z-index: 7;\n"])), BackgroundPallet.faqBottom);
export default FaqSection;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
