var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { styleMaxWidth, styleMinWidth, titleText } from 'src/common/styleUtil';
import { AddressContext, NetworkIdContext, Web3Context } from 'src/context';
import styled from 'styled-components';
import BlackDog from 'resource/etc/blackpuppy.png';
var OwnList = function () {
    var web3Service = useContext(Web3Context)[0];
    var _a = useContext(AddressContext), address = _a[0], setAddress = _a[1];
    var _b = useContext(NetworkIdContext), networkId = _b[0], setNetworkId = _b[1];
    var _c = useState([]), ownList = _c[0], setOwnList = _c[1];
    useEffect(function () {
        (function () {
            return __awaiter(this, void 0, void 0, function () {
                var _ownList;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, web3Service.getOwnList(address)];
                        case 1:
                            _ownList = _a.sent();
                            setOwnList(_ownList);
                            return [2 /*return*/];
                    }
                });
            });
        })();
    }, [address, networkId]);
    var itemList = ownList.map(function (row) { return React.createElement(OwnItem, { key: "tok_id".concat(row), src: "https://public.countrymixdog.io/sprite/".concat(row, ".png"), alt: "your sgjj ".concat(row) }); });
    var shadowList = [];
    for (var i = 0; i < 5 - itemList.length; i++) {
        shadowList.push(React.createElement(OwnItem, { style: { background: '#666', opacity: 0.33 }, key: "shadow_id".concat(i), src: BlackDog, alt: "sgjj skeleton ".concat(i) }));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(OwnListTitle, null,
            React.createElement("div", null, "Your"),
            " ",
            React.createElement("div", null, "SGJJ")),
        React.createElement(OwnListContainer, null,
            itemList,
            shadowList)));
};
var OwnListTitle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  margin: 0 20px;\n"], ["\n  ", "\n  margin: 0 20px;\n"])), titleText);
var OwnListContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n"])));
var OwnItem = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n  ", "\n  width: 230px;\n  height: 230px;\n  border-radius: 20px;\n  margin: 10px;\n"], ["\n  ", "\n  ", "\n  width: 230px;\n  height: 230px;\n  border-radius: 20px;\n  margin: 10px;\n"])), styleMinWidth(767, "\n    width: 18%;\n    height: auto;\n    margin: 1%;\n  "), styleMaxWidth(767, "\n    width: 30%;\n    height: auto;\n    margin: 1%;\n  "));
export default OwnList;
var templateObject_1, templateObject_2, templateObject_3;
