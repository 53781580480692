var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from 'react';
import styled from 'styled-components';
import { MenuItem, Select } from '@mui/material';
import { MdLanguage } from 'react-icons/md';
var LanguageSelect = function (_a) {
    var locale = _a.locale;
    return (React.createElement(NavItem, null,
        React.createElement("div", null,
            React.createElement(MdLanguage, { color: '#ddf' }),
            React.createElement(Select, { value: locale, MenuProps: {
                    disableScrollLock: true,
                }, onChange: function (e) {
                    var value = e.target.value;
                    if (value === 'en') {
                        location.href = "/en";
                        return;
                    }
                    location.href = "/ko";
                }, sx: {
                    color: '#ddf',
                    '& fieldset': {
                        border: '0 !important',
                        borderWidth: '0 !important',
                        padding: '0 !important',
                    },
                    '& svg': {
                        color: '#ddf',
                        bottom: 0,
                        top: 'auto',
                        right: '1px',
                    },
                    '& .MuiInputBase-input': {
                        padding: '10px 0 0 23px',
                        marginLeft: '-20px',
                        fontSize: '14px',
                    },
                    '& .MuiSelect-select': {
                        paddingRight: '23px !important'
                    }
                } },
                React.createElement(MenuItem, { value: 'ko' }, "Kor"),
                React.createElement(MenuItem, { value: 'en' }, "Eng")))));
};
var NavItem = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-flex;\n  align-items: baseline;\n  gap: 3px;\n  font-size: 24px;\n  color: #ddd;\n  font-family: 'BMJUA';\n  text-decoration: none;\n  padding: 10px;\n  padding-right: 0;\n"], ["\n  display: inline-flex;\n  align-items: baseline;\n  gap: 3px;\n  font-size: 24px;\n  color: #ddd;\n  font-family: 'BMJUA';\n  text-decoration: none;\n  padding: 10px;\n  padding-right: 0;\n"])));
export default LanguageSelect;
var templateObject_1;
